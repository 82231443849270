import React, { useEffect } from "react";
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Landing from './components/landing/Landing.js';
import Footer from './components/landing/footer/Footer.js';
import Join from "./components/Join/Join.js";
import Email from "./components/Email/Email.js";
import Connect from "./components/Connect/Connect.js";
import Villaa from "./components/villaa/Villaa.js";
import useEagerConnect from "./hooks/useEagerConnect";
import Triples from "./components/Triples/Triples.js";
import Apartments from "./components/Apartments/Apartments.js";
import Login from "./components/login/Login.js";
import Signup from "./components/login/Signup.js";
// import Forgotpassword from "./components/login/Forgotpassword.js";
import axios from "axios";
import Environment from "./utils/Environment.js";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEagerConnect();

  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 18, fontWeight: 300 }} theme="light" position="top-right" />

      <Router>
        <ConditionalWrapper>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/join' element={<Join />} />
            <Route path='/email' element={<Email />} />
            <Route path='/connect' element={<Connect />} />
            <Route path='/villaa/:id' element={<Villaa />} />
            <Route path='/Triples' element={<Triples />} />
            <Route path='/apartments' element={<Apartments />} />
            <Route path='/login' element={<Login />} />
            {/* <Route path='https://my.estateslicenft.com/signup' element={<Signup />} /> */}
            {/* <Route path='/Forgotpassword' element={<Forgotpassword />} /> */}
          </Routes>
        </ConditionalWrapper>
      </Router>
    </>
  );
}

function ConditionalWrapper({ children }) {
  const location = useLocation();
  const fullScreenRoutes = ["/login", "/signup"];

  // Check if the current route matches any of the full-screen routes
  const isFullScreen = fullScreenRoutes.includes(location.pathname);

  return (
    <>
      {!isFullScreen && <nav> {/* Replace this with your Navbar component */}
        {/* <Navbar /> */}
      </nav>}
      {children}
      {!isFullScreen && <Footer />}
    </>
  );
}

export default App;
