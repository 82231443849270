import React from "react";
import "./join.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbarmint from "../landing/headermint/Navbarmint";
import { Link } from "react-router-dom";

const Join = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="  leftrightmarginonly">
          <section className="joinhead">
            <Navbarmint />
            <div className="joinmain">
              <p className="ypoierrt">Estate Slice Property NFT</p>
              <img src="\assets\backkkkimg.svg" className="backkkimg" />

              {/* <div className="backkkimg forscroolbakkh">
                <div className=" onlydivscroolingdiv">
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                  <p className="inernfttpara">
                    <span className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                        <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" stroke-opacity="0.4" stroke-width="2" />
                      </svg>
                    </span>
                    Estate Slice Property NFT
                  </p>
                </div>
              </div> */}

              {/* <div className="backkkimg forscroolbakkh">
                <div className="onlydivscroolingdiv">
                  <div className="scroll-content">
               
                    {[...Array(1)].map((_, index) => (
                      <p key={index} className="inernfttpara">
                        <span className="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                            <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" strokeOpacity="0.4" strokeWidth="2" />
                          </svg>
                        </span>
                        Estate Slice Property NFT
                      </p>
                    ))}
                  </div> 
          
                  <div className="scroll-content">
          
                    {[...Array(900)].map((_, index) => (
                      <p key={index} className="inernfttpara">
                        <span className="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <circle cx="6.05189" cy="6.36294" r="6" transform="rotate(-4.62222 6.05189 6.36294)" fill="#FF8A00" />
                            <circle cx="6.05189" cy="6.36294" r="5" transform="rotate(-4.62222 6.05189 6.36294)" stroke="white" strokeOpacity="0.4" strokeWidth="2" />
                          </svg>
                        </span>
                        Estate Slice Property NFT
                      </p>
                    ))}
                  </div>
             

                </div>
              </div> */}

              <h1>Join The Waiting List</h1>

              <h2>
                Est adipisci voluptatum aut voluptas rerum ut dolor neque quo
                eveniet impedit qui consectetur quia
              </h2>
              <Link to="/Connect">
                <button>Join Now</button>
              </Link>

              <div className="myyyyyyyyyy">
                <img src="\assets\imgleft.svg" className="imgleftt" />
              </div>

              <div className="myyyyyyyyyy22">
                <img src="\assets\imgright.svg" className="imgright" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Join;
