import React, { useState, useEffect } from "react";
import "./navbarmint.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import Button from 'react-bootstrap/Button';
import Offcanvas from "react-bootstrap/Offcanvas";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import { Dropdown } from "react-bootstrap";
import { setupNetwork } from "../../../utils/wallet";
import axios from "axios";
import Environment from "../../../utils/Environment";
import { toast } from "react-toastify";

const Navbarmint = () => {
  const navigate = useNavigate();

  const token = localStorage?.getItem("authToken");
  const profileImage = localStorage?.getItem("profileImage");
  const userName = localStorage?.getItem("userName");

  let { account, chainId } = useWeb3React();
  const { login, logout } = useAuth();

  const [profile, setProfile] = useState(null);
  console.log(profile, "profileprofileprofile");

  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const [isModalOpen, setModalOpen] = useState(false);
  // const [showsidebar, setShowSidebar] = useState(false);

  // Function to handle clicks outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    handleCloseSidebar();
  };

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      let res = await setupNetwork();
      console.log(res, "res in setupNetwork");

      if (res) {
        login("injected");
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", "true");
        handleClose();
      }
    }
  };

  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
      handleClose();
    } else {
      try {
        handleClose();
        login("walletconnect");
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");

        // if (account) {
        //   await updaterUserWallet();
        // }
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };

  const logoutHandle = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };

  const logoutUserHandle = () => {
    localStorage?.removeItem("authToken");
    localStorage?.removeItem("referalAddress");
    window?.location?.reload();

    localStorage.removeItem("profileImage");
    localStorage.removeItem("userName");
  };

  useEffect(() => {
    // Update the timer every second
    if (chainId != 11155111) {
      setupNetwork(11155111);
    }
  }, [chainId]);

  const getUserProfile = async () => {
    try {
      const response = await axios.get(`${Environment?.API_URL}users/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(
        "getUserProfile Successful: response.data.user?.referalBy?.walletAddress",
        response?.data
      );
      setProfile(response?.data?.data);
    } catch (err) {
      console.log(err, "erfref");
    }
  };

  // const updaterUserWallet = async () => {
  //   // setLoading(true);

  //   try {
  //     const response = await axios.post(
  //       // `${Environment.API_URL}users/${profile?._id}`,
  //       `${Environment.API_URL}users/check-wallet`,
  //       { walletAddress: account }, // The request body (if any) should go here; it's currently empty
  //       { headers: { Authorization: `Bearer ${token}` } } // Configuration with headers
  //     );
  //     console.log(
  //       'updaterUserWallet Successful: response.data.user?.referalBy?.walletAddress',
  //       response?.data
  //     );
  //     // setProfile(response?.data?.data);
  //     // handleShow();
  //     // navigate('/');
  //   } catch (err) {
  //     console.log(err.response?.data?.message, "erfref");
  //     toast?.error(err.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    if (token) {
      getUserProfile();
    }
  }, [token]);

  // useEffect(() => {
  //   // Check if account and profile are both defined
  //   if (account) {
  //     updaterUserWallet();
  //   }
  //   // if (account && profile) {
  //   //   // Call API only if walletAddress is not set in profile
  //   //   if (profile.walletAddress) {
  //   //     console.log("Calling updaterUserWallet(): walletAddress is missing");
  //   //     updaterUserWallet();
  //   //   } else if (profile.walletAddress !== account?.toLocaleLowerCase()) {
  //   //     console.log("Error: Wallet address mismatch");
  //   //     toast?.error("You are not connected with the correct wallet");
  //   //   } else {
  //   //     updaterUserWallet();
  //   //   }
  //   // }
  // }, [account]); // Only depend on profile and account

  return (
    <section className="main-navbar-mobile   main_navbarmint">
      <div className="custom-container   padingzero">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12  m-auto   p-md-0  nav_boder ">
            <nav className="navbar  navbar-expand-xl ">
              <a
                href="#"
                className="navbar-brand animate__flash dsjkvjkdbkdjbjkd"
              >
                <Link to="/">
                  {/* <img
                    src="\Logo\Logo.png"
                    alt="img"
                    width={'204px'}
                    className="img-fluid animate__animated animate__bounce"
                  /> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="167"
                    height="32"
                    viewBox="0 0 167 32"
                    fill="none"
                  >
                    <path
                      d="M49.1365 10.8367V15.2252H54.2994V17.2258H49.1365V21.8079H54.9448V23.8086H46.6841V8.83605H54.9448V10.8367H49.1365Z"
                      fill="#10312B"
                    />
                    <path
                      d="M62.2679 24.0022C61.3357 24.0022 60.4967 23.8373 59.7509 23.5074C59.0195 23.1632 58.4387 22.7043 58.0084 22.1306C57.5782 21.5426 57.3487 20.8901 57.32 20.173H59.8585C59.9015 20.675 60.1382 21.098 60.5684 21.4422C61.013 21.7721 61.5651 21.937 62.2248 21.937C62.9132 21.937 63.4439 21.8079 63.8167 21.5498C64.204 21.2773 64.3976 20.9331 64.3976 20.5172C64.3976 20.0726 64.1825 19.7428 63.7522 19.5276C63.3363 19.3125 62.6694 19.0759 61.7516 18.8177C60.8624 18.5739 60.1382 18.3373 59.5788 18.1078C59.0195 17.8784 58.5319 17.527 58.116 17.0537C57.7144 16.5805 57.5137 15.9566 57.5137 15.1822C57.5137 14.5511 57.7001 13.9775 58.073 13.4612C58.4459 12.9305 58.9765 12.5146 59.6649 12.2135C60.3676 11.9123 61.1707 11.7617 62.0743 11.7617C63.4224 11.7617 64.5051 12.1059 65.3226 12.7943C66.1544 13.4684 66.599 14.3934 66.6564 15.5694H64.204C64.1609 15.0387 63.9458 14.6157 63.5586 14.3002C63.1714 13.9846 62.6479 13.8269 61.9882 13.8269C61.3428 13.8269 60.8481 13.9488 60.5039 14.1926C60.1597 14.4364 59.9876 14.7591 59.9876 15.1607C59.9876 15.4762 60.1023 15.7415 60.3318 15.9566C60.5612 16.1717 60.8409 16.3438 61.1707 16.4729C61.5006 16.5876 61.9882 16.7382 62.6336 16.9247C63.4941 17.1541 64.1968 17.3908 64.7418 17.6346C65.3011 17.864 65.7815 18.2082 66.1831 18.6672C66.5847 19.1261 66.7926 19.7356 66.807 20.4957C66.807 21.1697 66.6205 21.7721 66.2476 22.3027C65.8748 22.8334 65.3441 23.2493 64.6557 23.5504C63.9817 23.8516 63.1857 24.0022 62.2679 24.0022Z"
                      fill="#10312B"
                    />
                    <path
                      d="M72.318 13.956V20.5172C72.318 20.9618 72.4183 21.2845 72.6191 21.4853C72.8343 21.6717 73.1928 21.7649 73.6947 21.7649H75.2006V23.8086H73.2645C72.1602 23.8086 71.314 23.5504 70.726 23.0341C70.138 22.5178 69.844 21.6789 69.844 20.5172V13.956H68.4457V11.9553H69.844V9.00815H72.318V11.9553H75.2006V13.956H72.318Z"
                      fill="#10312B"
                    />
                    <path
                      d="M76.6434 17.8282C76.6434 16.6378 76.8872 15.5837 77.3748 14.6659C77.8768 13.748 78.5508 13.0381 79.397 12.5362C80.2575 12.0199 81.204 11.7617 82.2366 11.7617C83.1688 11.7617 83.9791 11.9482 84.6675 12.321C85.3702 12.6796 85.9296 13.1313 86.3455 13.6763V11.9553H88.8194V23.8086H86.3455V22.0446C85.9296 22.6039 85.3631 23.07 84.646 23.4429C83.9289 23.8158 83.1115 24.0022 82.1936 24.0022C81.1753 24.0022 80.2431 23.744 79.397 23.2277C78.5508 22.6971 77.8768 21.9657 77.3748 21.0335C76.8872 20.087 76.6434 19.0185 76.6434 17.8282ZM86.3455 17.8712C86.3455 17.0537 86.1734 16.3438 85.8292 15.7415C85.4993 15.1391 85.0619 14.6802 84.5169 14.3647C83.9719 14.0492 83.3839 13.8914 82.7529 13.8914C82.1219 13.8914 81.5339 14.0492 80.9889 14.3647C80.4439 14.6659 79.9993 15.1176 79.6551 15.72C79.3253 16.308 79.1604 17.0107 79.1604 17.8282C79.1604 18.6456 79.3253 19.3627 79.6551 19.9794C79.9993 20.5961 80.4439 21.0694 80.9889 21.3992C81.5482 21.7147 82.1362 21.8725 82.7529 21.8725C83.3839 21.8725 83.9719 21.7147 84.5169 21.3992C85.0619 21.0837 85.4993 20.6248 85.8292 20.0224C86.1734 19.4057 86.3455 18.6887 86.3455 17.8712Z"
                      fill="#10312B"
                    />
                    <path
                      d="M94.9016 13.956V20.5172C94.9016 20.9618 95.002 21.2845 95.2028 21.4853C95.4179 21.6717 95.7765 21.7649 96.2784 21.7649H97.7843V23.8086H95.8482C94.7439 23.8086 93.8977 23.5504 93.3097 23.0341C92.7217 22.5178 92.4277 21.6789 92.4277 20.5172V13.956H91.0294V11.9553H92.4277V9.00815H94.9016V11.9553H97.7843V13.956H94.9016Z"
                      fill="#10312B"
                    />
                    <path
                      d="M110.908 17.5915C110.908 18.0361 110.88 18.4377 110.822 18.7962H101.766C101.837 19.7428 102.189 20.5029 102.82 21.0765C103.451 21.6502 104.225 21.937 105.143 21.937C106.462 21.937 107.395 21.3849 107.94 20.2806H110.586C110.227 21.3705 109.575 22.2669 108.628 22.9696C107.696 23.658 106.534 24.0022 105.143 24.0022C104.01 24.0022 102.992 23.7512 102.088 23.2493C101.199 22.733 100.496 22.0159 99.98 21.098C99.4781 20.1658 99.2271 19.0902 99.2271 17.8712C99.2271 16.6522 99.4709 15.5837 99.9585 14.6659C100.46 13.7337 101.156 13.0166 102.045 12.5146C102.949 12.0127 103.981 11.7617 105.143 11.7617C106.262 11.7617 107.258 12.0055 108.133 12.4931C109.008 12.9807 109.689 13.6691 110.177 14.5583C110.664 15.4331 110.908 16.4442 110.908 17.5915ZM108.348 16.8171C108.334 15.9136 108.011 15.1893 107.38 14.6444C106.749 14.0994 105.968 13.8269 105.035 13.8269C104.189 13.8269 103.465 14.0994 102.863 14.6444C102.26 15.175 101.902 15.8992 101.787 16.8171H108.348Z"
                      fill="#10312B"
                    />
                    <path
                      d="M123.836 23.9592C122.832 23.9592 121.929 23.7871 121.126 23.4429C120.322 23.0843 119.691 22.5824 119.232 21.937C118.774 21.2916 118.544 20.5387 118.544 19.6782H121.169C121.226 20.3236 121.477 20.8542 121.922 21.2701C122.38 21.686 123.019 21.894 123.836 21.894C124.682 21.894 125.342 21.6932 125.815 21.2916C126.289 20.8757 126.525 20.3451 126.525 19.6997C126.525 19.1978 126.375 18.7891 126.073 18.4735C125.787 18.158 125.421 17.9142 124.976 17.7421C124.546 17.57 123.944 17.3836 123.169 17.1828C122.194 16.9247 121.398 16.6665 120.781 16.4084C120.179 16.1359 119.663 15.72 119.232 15.1607C118.802 14.6013 118.587 13.8556 118.587 12.9234C118.587 12.0629 118.802 11.31 119.232 10.6646C119.663 10.0192 120.265 9.52444 121.04 9.18024C121.814 8.83605 122.71 8.66395 123.729 8.66395C125.177 8.66395 126.36 9.02966 127.278 9.76107C128.21 10.4782 128.727 11.4677 128.827 12.7298H126.116C126.073 12.1848 125.815 11.7187 125.342 11.3315C124.869 10.9442 124.245 10.7506 123.47 10.7506C122.768 10.7506 122.194 10.9299 121.749 11.2884C121.305 11.647 121.083 12.1633 121.083 12.8373C121.083 13.2963 121.219 13.6763 121.491 13.9775C121.778 14.2643 122.137 14.4938 122.567 14.6659C122.997 14.838 123.585 15.0244 124.331 15.2252C125.32 15.4977 126.124 15.7702 126.74 16.0427C127.371 16.3151 127.902 16.7382 128.332 17.3119C128.777 17.8712 128.999 18.6241 128.999 19.5707C128.999 20.3308 128.791 21.0478 128.375 21.7219C127.974 22.3959 127.378 22.9409 126.59 23.3568C125.815 23.7584 124.897 23.9592 123.836 23.9592Z"
                      fill="#10312B"
                    />
                    <path
                      d="M134.345 7.88951V23.8086H131.893V7.88951H134.345Z"
                      fill="#10312B"
                    />
                    <path
                      d="M138.813 10.3849C138.368 10.3849 137.995 10.2343 137.694 9.93317C137.393 9.632 137.242 9.25912 137.242 8.81454C137.242 8.36995 137.393 7.99707 137.694 7.6959C137.995 7.39473 138.368 7.24414 138.813 7.24414C139.243 7.24414 139.609 7.39473 139.91 7.6959C140.211 7.99707 140.362 8.36995 140.362 8.81454C140.362 9.25912 140.211 9.632 139.91 9.93317C139.609 10.2343 139.243 10.3849 138.813 10.3849ZM140.017 11.9553V23.8086H137.565V11.9553H140.017Z"
                      fill="#10312B"
                    />
                    <path
                      d="M142.42 17.8712C142.42 16.6522 142.663 15.5837 143.151 14.6659C143.653 13.7337 144.341 13.0166 145.216 12.5146C146.091 12.0127 147.095 11.7617 148.228 11.7617C149.662 11.7617 150.845 12.1059 151.778 12.7943C152.724 13.4684 153.362 14.4364 153.692 15.6985H151.046C150.831 15.1105 150.487 14.6515 150.014 14.3217C149.54 13.9918 148.945 13.8269 148.228 13.8269C147.224 13.8269 146.421 14.1854 145.819 14.9025C145.231 15.6052 144.937 16.5948 144.937 17.8712C144.937 19.1476 145.231 20.1443 145.819 20.8614C146.421 21.5785 147.224 21.937 148.228 21.937C149.648 21.937 150.587 21.3132 151.046 20.0654H153.692C153.348 21.2701 152.703 22.231 151.756 22.9481C150.809 23.6508 149.633 24.0022 148.228 24.0022C147.095 24.0022 146.091 23.7512 145.216 23.2493C144.341 22.733 143.653 22.0159 143.151 21.098C142.663 20.1658 142.42 19.0902 142.42 17.8712Z"
                      fill="#10312B"
                    />
                    <path
                      d="M167 17.5915C167 18.0361 166.971 18.4377 166.914 18.7962H157.857C157.929 19.7428 158.28 20.5029 158.911 21.0765C159.542 21.6502 160.317 21.937 161.235 21.937C162.554 21.937 163.486 21.3849 164.031 20.2806H166.677C166.319 21.3705 165.666 22.2669 164.719 22.9696C163.787 23.658 162.626 24.0022 161.235 24.0022C160.102 24.0022 159.083 23.7512 158.18 23.2493C157.291 22.733 156.588 22.0159 156.072 21.098C155.57 20.1658 155.319 19.0902 155.319 17.8712C155.319 16.6522 155.562 15.5837 156.05 14.6659C156.552 13.7337 157.248 13.0166 158.137 12.5146C159.04 12.0127 160.073 11.7617 161.235 11.7617C162.353 11.7617 163.35 12.0055 164.225 12.4931C165.1 12.9807 165.781 13.6691 166.268 14.5583C166.756 15.4331 167 16.4442 167 17.5915ZM164.44 16.8171C164.425 15.9136 164.103 15.1893 163.472 14.6444C162.841 14.0994 162.059 13.8269 161.127 13.8269C160.281 13.8269 159.557 14.0994 158.954 14.6444C158.352 15.175 157.993 15.8992 157.879 16.8171H164.44Z"
                      fill="#10312B"
                    />
                    <path
                      d="M24.1814 19.8955C23.6776 20.4302 23.1791 20.9701 22.6702 21.4997C22.4945 21.6831 22.5514 21.9026 22.5514 22.1119C22.5488 25.067 22.541 28.0248 22.5591 30.9799C22.5617 31.4061 22.492 31.5663 22.0141 31.5585C20.2291 31.525 18.4441 31.5353 16.6617 31.5534C16.3001 31.556 16.2148 31.4475 16.2148 31.0987C16.2278 27.5288 16.2252 23.9614 16.2174 20.3915C16.2174 20.1564 16.2846 19.9937 16.4499 19.8232C18.5345 17.7127 20.614 15.5997 22.6857 13.4763C22.8924 13.2645 23.1068 13.1793 23.3987 13.1818C24.8401 13.1922 26.2841 13.1715 27.7255 13.1973C28.1414 13.2051 28.2421 13.0708 28.2344 12.6729C28.2111 11.2496 28.2369 9.82371 28.2163 8.40038C28.2111 8.06198 28.317 7.82949 28.5573 7.60476C29.0687 7.1217 29.5518 6.61023 30.0555 6.12201C30.1769 6.00319 30.2725 5.76037 30.4611 5.82495C30.6677 5.89728 30.5566 6.1401 30.5566 6.30542C30.5566 10.7407 30.5489 15.1735 30.5489 19.6088C30.5489 23.4112 30.5515 27.2162 30.5721 31.0187C30.5747 31.4346 30.4791 31.5637 30.0452 31.5585C28.3325 31.5327 26.6199 31.5301 24.9072 31.5585C24.4552 31.5663 24.3854 31.4113 24.3854 31.0083C24.3932 27.5288 24.3803 24.0518 24.3725 20.5723C24.3725 20.3811 24.3725 20.19 24.3725 20.0014C24.3053 19.9678 24.2434 19.9317 24.1814 19.8955Z"
                      fill="#F6881F"
                    />
                    <path
                      d="M14.4166 15.8579C14.4166 20.9054 14.4114 25.9555 14.4269 31.0031C14.4295 31.4474 14.3339 31.5688 13.8767 31.561C12.1098 31.5275 10.3429 31.5352 8.57602 31.5481C8.22729 31.5507 8.11621 31.4629 8.11621 31.0986C8.12913 22.8583 8.13171 14.6206 8.12913 6.38024C8.12913 6.14258 8.20921 5.98501 8.36678 5.82227C9.58087 4.58235 10.7924 3.33984 11.9987 2.08958C12.5541 1.51353 13.094 0.92457 13.639 0.340772C13.794 0.175449 13.9542 -0.0570367 14.1893 0.012709C14.4863 0.10312 14.4321 0.407935 14.4321 0.658503C14.4321 5.72411 14.4321 10.7923 14.4321 15.8579C14.4295 15.8579 14.4243 15.8579 14.4166 15.8579Z"
                      fill="#10312B"
                    />
                    <path
                      d="M6.26168 10.3997C6.46317 11.4045 6.396 12.3551 6.33142 13.298C6.30043 13.732 6.34692 14.1556 6.34692 14.5844C6.34176 20.0633 6.33659 25.5448 6.35467 31.0237C6.35726 31.4887 6.22551 31.5765 5.78896 31.5688C4.00398 31.5404 2.21901 31.5429 0.436619 31.561C0.0749744 31.5636 -0.00252151 31.4577 6.16648e-05 31.1141C0.0129775 26.3921 0.0129773 21.6675 0.0129773 16.9454C0.0129773 16.7284 0.0258932 16.5476 0.206716 16.3694C2.16476 14.4294 4.10989 12.4765 6.06277 10.5262C6.09894 10.4927 6.14802 10.472 6.26168 10.3997Z"
                      fill="#10312B"
                    />
                    <path
                      d="M16.2119 11.9419C19.7173 8.16275 23.4861 4.85887 26.9114 1.21143C26.9553 1.23467 26.9993 1.25792 27.0458 1.28375C27.0458 1.43616 27.0458 1.58857 27.0458 1.74098C27.0458 4.96736 27.038 8.19375 27.0561 11.4176C27.0587 11.836 26.9605 11.9574 26.5265 11.9549C23.14 11.9342 19.7534 11.9419 16.2119 11.9419Z"
                      fill="#10312B"
                    />
                  </svg>
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShowSidebar}
              >
                <span className="togg">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 17H19M5 12H19M5 7H19"
                        stroke="#FF8A00"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg p-0"
                id="navbarSupportedContent"
              >
                {/* <Link to="/" className="newwwwhommee">
                  <button className="homebtnns">
                    Home
                  </button>
                </Link> */}

                <ul className="navbar-nav m-auto">
                  <li class="nav-item active">
                    <HashLink to="/">Home</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink to="/#about">About us</HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink to="/#benefits">Benefits</HashLink>
                  </li>

                  <li className="nav-item">
                    <HashLink to="/#properties">Properties</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="/#roadmap">Roadmap</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="/#news">News</HashLink>
                  </li>
                  <li className="nav-item active">
                    <HashLink to="/#roadmap">FAQ</HashLink>
                  </li>
                  {/* <li className="nav-item active">
                    <HashLink to="#roadmap">Whitepaper</HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink
                      to="/join"
                      style={
                        isActive("#")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Join whitelist
                    </HashLink>
                  </li> */}
                </ul>

                <div className="navrightbtsns">
                  

                  {token ? (
                    <>
                      {account ? (
                        <button
                          class="btn button-hedaer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => logoutHandle()}
                        >
                          Disconnect
                        </button>
                      ) : (
                        <button
                          class="btn button-hedaer"
                          onClick={() => {
                            setModalOpen(true);
                            handleCloseSidebar();
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          // class="btn button-hedaer"
                        >
                          Connect Wallet
                        </button>
                      )}

                      {account && (
                        <>
                          {account.slice(0, 5)}...{account.slice(-5)}
                        </>
                      )}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="white"
                          className="whitebtn"
                          style={{ gap: 3 }}
                        >
                          <div
                            className="whitebtnimg"
                            style={{ width: "30px", height: "30px" }}
                          >
                            <img
                              src={
                                profileImage
                                  ? profileImage
                                  : "https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg"
                              }
                              alt="btninnerimg"
                              className="btninnerimg"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "100%",
                              }}
                            />
                          </div>
                          <p className="btninnerpara">{userName}</p>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {/* {
                              account ?
                                <Dropdown.Item onClick={() => logoutHandle()}>Disconnect</Dropdown.Item>
                                :
                                <Dropdown.Item onClick={() => { setModalOpen(true); handleCloseSidebar(); }}>Connect Wallet</Dropdown.Item>
                            } */}
                          <Dropdown.Item href="https://my.estateslicenft.com/">
                            User Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => logoutUserHandle()}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <>
                    <Link to="/login" class="btn button-hedaer">
                      Login
                    </Link>
                    <Link
                                            to="https://my.estateslicenft.com/signup"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal"
                                            class="btn button-hedaer"
                                          >
                                            Signup
                                          </Link>
                    </>  
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className={`wallet-modal ${isModalOpen ? "show" : "hide"}`}>
        <div
          className="modal fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!isModalOpen}
          style={{ display: isModalOpen ? "block" : "none" }}
          onClick={handleOutsideClick} // Handle outside click
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div className="cross-icon">
                  <h3 class="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>
                  <button
                    className="cross-img1"
                    // type="button"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"

                    type="button"
                    aria-label="Close"
                    onClick={() => setModalOpen(false)}
                  >
                    <img
                      src="/assets/navbar/Close_MD.svg"
                      className="img-fluid"
                      alt="close"
                      // onClick={() => isModalOpen(false)}
                    />
                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button" onClick={connectMetaMask1}>
                    <img
                      src="\assets\navbar\metamask.svg"
                      className="img-fluid"
                    />
                    <h3 className=""> MetaMask</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                  <button className="modal-button" onClick={trustWallet}>
                    <img
                      src="\assets\navbar\walletconnect.svg"
                      className="img-fluid"
                    />
                    <h3 className="">WalletConnect</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                </div>
                <p className="text-center mt-3  connecting">
                  By connecting, I accept Estate Slice NFT{" "}
                  <span className="common connectinggg">Terms of Service </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        placement="top"
        className="mobile_sidebar"
        show={showsidebar}
        onHide={handleCloseSidebar}
      >
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand ">
              <Link to="/">
                {/* <img
                  src="\Logo\Logo.png"
                  alt="img"
                  width={'204px'}
                  className="img-fluid animate__animated animate__bounce"
                /> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="148"
                  height="30"
                  viewBox="0 0 148 30"
                  fill="none"
                >
                  <path
                    d="M0 29.5668C7.82462 19.315 22.2343 11.3172 22.2343 11.3172C22.2343 11.3172 37.2706 19.4512 44.4687 29.5668C37.2744 13.1778 22.2343 0 22.2343 0C22.2343 0 7.82462 12.9552 0 29.5668Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M0.113922 29.4393C7.94047 25.1021 22.3483 21.718 22.3483 21.718C22.3483 21.718 37.3845 25.1605 44.5826 29.4393C37.3903 22.5046 22.3483 16.9314 22.3483 16.9314C22.3483 16.9314 7.94047 22.4117 0.113922 29.4393Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M0.113922 29.4394C7.94047 27.6047 22.3483 26.1676 22.3483 26.1676C22.3483 26.1676 37.3845 27.622 44.5826 29.4329C37.3903 26.5004 22.3483 24.1406 22.3483 24.1406C22.3483 24.1406 7.94047 26.4659 0.113922 29.4394Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M58.149 22.6514C54.804 22.6514 52.6009 21.0749 52.4291 18.4575L52.419 18.3059H54.9151L54.9353 18.4272C55.1273 19.6803 56.4108 20.4585 58.2399 20.4585C60.0792 20.4585 61.2313 19.6399 61.2313 18.3868V18.3766C61.2313 17.2852 60.4733 16.6991 58.5936 16.305L57.0272 15.9816C54.1572 15.3853 52.7424 14.0513 52.7424 11.8786V11.8685C52.7525 9.3016 55.0061 7.5634 58.149 7.5634C61.3323 7.5634 63.4141 9.24097 63.5657 11.7371L63.5758 11.9089H61.0797L61.0595 11.7876C60.8877 10.5143 59.7659 9.75637 58.1288 9.75637C56.4209 9.76647 55.3598 10.5648 55.3598 11.727V11.7371C55.3598 12.7679 56.1581 13.3743 57.9166 13.7381L59.4931 14.0615C62.5248 14.688 63.8487 15.9209 63.8487 18.1947V18.2048C63.8487 20.9233 61.7062 22.6514 58.149 22.6514Z"
                    fill="#10312B"
                  />
                  <path
                    d="M65.5465 22.3988V11.6866H68.0628V13.4046H68.1133C68.5681 12.2323 69.6696 11.4643 71.0845 11.4643C72.5801 11.4643 73.6614 12.2525 74.0556 13.6269H74.1162C74.6316 12.2929 75.9049 11.4643 77.4511 11.4643C79.5633 11.4643 80.9478 12.8993 80.9478 15.0417V22.3988H78.4314V15.6683C78.4314 14.3444 77.7442 13.5865 76.5113 13.5865C75.2986 13.5865 74.4699 14.4859 74.4699 15.7794V22.3988H72.0243V15.5369C72.0243 14.3343 71.2967 13.5865 70.1244 13.5865C68.9117 13.5865 68.0628 14.5364 68.0628 15.8401V22.3988H65.5465Z"
                    fill="#10312B"
                  />
                  <path
                    d="M86.1321 22.5807C83.9896 22.5807 82.5445 21.2568 82.5445 19.3468V19.3266C82.5445 17.4267 84.0199 16.2847 86.6171 16.123L89.4165 15.9512V15.2135C89.4165 14.1322 88.709 13.4753 87.4357 13.4753C86.2432 13.4753 85.5156 14.0311 85.3539 14.8194L85.3337 14.9103H82.9992L83.0093 14.7891C83.1609 12.8892 84.8082 11.4643 87.5267 11.4643C90.2047 11.4643 91.9328 12.8791 91.9328 15.0316V22.3988H89.4165V20.7515H89.3558C88.7394 21.8733 87.5469 22.5807 86.1321 22.5807ZM85.0507 19.2458C85.0507 20.125 85.7783 20.6606 86.8799 20.6606C88.325 20.6606 89.4165 19.7005 89.4165 18.4272V17.6187L86.9708 17.7703C85.7278 17.8511 85.0507 18.3868 85.0507 19.2356V19.2458Z"
                    fill="#10312B"
                  />
                  <path
                    d="M93.9641 22.3988V11.6866H96.4804V13.536H96.531C96.8645 12.2424 97.7538 11.4643 98.9867 11.4643C99.3 11.4643 99.593 11.5148 99.785 11.5653V13.8391C99.5728 13.7583 99.1888 13.6976 98.7644 13.6976C97.3394 13.6976 96.4804 14.5971 96.4804 16.1837V22.3988H93.9641Z"
                    fill="#10312B"
                  />
                  <path
                    d="M105.06 22.611C102.817 22.611 101.756 21.7015 101.756 19.4984V13.6269H100.24V11.6866H101.756V8.96811H104.312V11.6866H106.303V13.6269H104.312V19.2862C104.312 20.3372 104.777 20.6707 105.697 20.6707C105.939 20.6707 106.132 20.6404 106.303 20.6302V22.52C106.01 22.5605 105.566 22.611 105.06 22.611Z"
                    fill="#10312B"
                  />
                  <path
                    d="M118.774 22.6514C114.58 22.6514 111.932 19.7611 111.932 15.1024V15.0923C111.932 10.4335 114.59 7.5634 118.774 7.5634C122.129 7.5634 124.595 9.65531 124.979 12.6264L124.989 12.7275H122.432L122.382 12.5355C121.957 10.868 120.674 9.82711 118.774 9.82711C116.227 9.82711 114.6 11.8584 114.6 15.0923V15.1024C114.6 18.3463 116.237 20.3877 118.774 20.3877C120.633 20.3877 121.937 19.3872 122.412 17.5884L122.442 17.4873H124.999L124.979 17.5884C124.605 20.5797 122.129 22.6514 118.774 22.6514Z"
                    fill="#10312B"
                  />
                  <path
                    d="M128.061 10.1505C127.273 10.1505 126.656 9.52393 126.656 8.7761C126.656 8.00806 127.273 7.3916 128.061 7.3916C128.849 7.3916 129.456 8.00806 129.456 8.7761C129.456 9.52393 128.849 10.1505 128.061 10.1505ZM126.798 22.3988V11.6866H129.314V22.3988H126.798Z"
                    fill="#10312B"
                  />
                  <path
                    d="M135.438 22.611C133.195 22.611 132.134 21.7015 132.134 19.4984V13.6269H130.618V11.6866H132.134V8.96811H134.691V11.6866H136.681V13.6269H134.691V19.2862C134.691 20.3372 135.155 20.6707 136.075 20.6707C136.318 20.6707 136.51 20.6404 136.681 20.6302V22.52C136.388 22.5605 135.944 22.611 135.438 22.611Z"
                    fill="#10312B"
                  />
                  <path
                    d="M139.845 25.956C139.36 25.956 138.854 25.8954 138.551 25.8449V23.9248C138.743 23.9652 139.036 24.0157 139.39 24.0157C140.35 24.0157 140.885 23.7428 141.209 22.8434L141.36 22.4089L137.51 11.6866H140.259L142.765 20.2563H142.836L145.352 11.6866H148L144.15 22.7626C143.331 25.1172 142.078 25.956 139.845 25.956Z"
                    fill="#10312B"
                  />
                </svg>
              </Link>
            </a>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav m-auto">
            {/* <button className="homebtnns">
                  Home
                </button> */}
            <li className="nav-item">
              <HashLink to="/#navbar">Home</HashLink>
            </li>
            <li className="nav-item  ">
              <HashLink to="/#about">About us</HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="/#benefits"> Benefits</HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="/#properties">Properties</HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="#roadmap">Roadmap</HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="/#news">News</HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="#roadmap">FAQ</HashLink>
            </li>
            {/* <li className="nav-item">
              <HashLink to="#roadmap">Whitepaper</HashLink>
            </li>
            <li className="nav-item ">
              <HashLink
                to="/join"
                style={
                  isActive("#")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Join whitelist
              </HashLink>
            </li> */}
            {token ? (
              <>
                {account && (
                  <>
                    {account.slice(0, 5)}...${account.slice(-5)}
                  </>
                )}
                <Dropdown>
                  <Dropdown.Toggle variant="white" className="whitebtn">
                    <div
                      className="whitebtnimg"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <img
                        src={
                          profileImage
                            ? profileImage
                            : "https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg"
                        }
                        alt="btninnerimg"
                        className="btninnerimg"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "100%",
                        }}
                      />
                    </div>
                    <p className="btninnerpara">{userName}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {account ? (
                      <Dropdown.Item onClick={() => logoutHandle()}>
                        Disconnect
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          setModalOpen(true);
                          handleCloseSidebar();
                        }}
                      >
                        Connect Wallet
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item href="https://my.estateslicenft.com/">
                      User Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => logoutUserHandle()}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
              <button style={{marginBottom: "10px", marginTop: "20px"}}
                onClick={() => navigate("/login")}
                className="btn button-hedaer"
              >
                Login
              </button>
                <button
                  className="btn button-header my-1" style={{width: "100%"}}
                  onClick={() => (window.location.href = "https://my.estateslicenft.com/signup")}
                >
                  Signup
                </button>
                </>
            )}
            {/* </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Navbarmint;
