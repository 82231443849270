import React from "react";
import "./faqs.scss";
import Accordion from "react-bootstrap/Accordion";
const Faqs = () => {
  return (
    <>
      <div className="new_lasttt">
        <section className="main-faqs" id="faqs">
          <div className="mysett">
            <div className="custom-container ">
              <div className="main_faqssssss">
                <div className="highlightsxxxx">
                  <p className="aboutdfdvv ">FAQs</p>
                  <h2 className="Pioneersss">
                    Expert Answers to Your Most Common Questions
                  </h2>
                  <p className=" faqs_para">
                    Find answers to the most common questions and get insights
                    into our services, processes, and policies to help you make
                    informed decisions.
                  </p>
                </div>
                <div className=" inner-upper p-0 ">
                  <div className="inner-side ">
                    <Accordion defaultActiveKey="0">
                      <div className="acordingouterdiv">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {" "}
                            <span className="digits mx-3">01</span>
                            <p className="textlast">
                              How do property NFTs work?
                            </p>
                          </Accordion.Header>
                          <Accordion.Body>
                            Real estate NFTs work like any other NFT. They are
                            purchased using a crypto-currency chosen by the
                            seller, held in a digital wallet and, if
                            speculative, resold at a profit to a buyer with the
                            right amount of money.
                            <span className="py-1">
                              Investments held in the form of FO tokens behave
                              more like shares, in that they represent a share
                              in a property project rather than just a real or
                              virtual object. Profits are paid out in the same
                              way as any other share-based investment, as the
                              property is simply a share in a business.
                            </span>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                      <div className="acordingouterdiv my-3">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            {" "}
                            <span className="digits mx-3">02</span>
                            <p className="textlast">How to buy NFTs</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            NFTs are bought and sold via a marketplace
                            specialising in NFTs, rather like Amazon (AMZN
                            3.71%) or Etsy (ETSY -0.74%), but for digital
                            assets. These marketplaces can be used to buy an NFT
                            at a fixed price or operate as a virtual auction,
                            similar to the exchange system for buying and
                            selling crypto-currencies and shares. NFT auction
                            prices are therefore volatile, with their value
                            varying according to demand. The greater the demand,
                            the higher the price. One of the main differences
                            between NFTs, equities and cryptocurrencies is that
                            equities and cryptocurrencies are fungible, meaning
                            that each unit is identical to the other.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                      <div className="acordingouterdiv my-3">
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            {" "}
                            <span className="digits mx-3">03</span>
                            <p className="textlast">How to sell NFTs</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            Once you own an NFT, that digital asset is (usually)
                            yours to do with as you please. You can keep it as a
                            collector's item, display it for all to see or use
                            it as part of a wider digital project. You can also
                            put it up for sale. Marketplaces charge fees for
                            selling NFTs. These fees can fluctuate depending on
                            the blockchain network used by the NFT, as the
                            blockchain computing required to verify the NFT
                            consumes energy, known as ‘gas charges’. To sell a
                            digital asset you own, the coin will need to be
                            uploaded to the marketplace of your choice, in this
                            case Estate Slice NFT. From there, you can choose to
                            put it up for sale at a fixed price or opt for an
                            auction in which buyers place bids.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                      <div className="acordingouterdiv my-3">
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            {" "}
                            <span className="digits mx-3">04</span>{" "}
                            <p className="textlast">Why invest?</p>
                          </Accordion.Header>
                          <Accordion.Body>
                            Investing can be the best financial decision you can
                            make. Even if you earn a regular salary from your
                            job, investing can make your hard-earned money work
                            for you. A wisely constructed investment portfolio
                            can, over time, allow you to accumulate considerable
                            wealth that you can use for your retirement, to send
                            your children to university or to achieve any other
                            financial goal. But always remember that any
                            investment involves risk, so only invest money you
                            are capable of losing.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                      <div className="acordingouterdiv my-3">
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            {" "}
                            <span className="digitssss mx-3">05</span>{" "}
                            <p className="textlast">
                              What are the Features and Benefits of Real Estate
                              NFT?
                            </p>
                          </Accordion.Header>
                          <Accordion.Body>
                            Full transparency on the transfer of ownership of
                            the property in full and not in installments. In
                            Real Estate NFT, there is no need to move or do
                            anything with the property, as is the case in art or
                            music. All the work revolves around paperwork.
                            Increasing marginal costs, reducing brokerage fees,
                            and other minor expenses are the only obstacles in
                            real estate that scare away potential clients. Using
                            NFT helps reduce all overhead costs in order to get
                            a great price. NFT does not reduce the paperwork
                            involved in real estate transactions, such as tax
                            forms, deed forms, etc.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="6" >
                        <Accordion.Header>  <span className='digits mx-3'>06</span>How to connect my Metamask account to my account?</Accordion.Header>
                          <Accordion.Body>
                            Simply click on CONNECT WALLET and your account will be automatically linked to your Metamask or other DES like Truswallet. Or contact us on Telegram, an agent will help you.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="7" >
                        <Accordion.Header>  <span className='digits mx-3'>07</span>How do I receive my referral fees?                        </Accordion.Header>
                          <Accordion.Body>
                          Your referral commissions will be 5% per NFT, it will be automatically sent to your wallet connected to your account each time you referrer buy an NFT.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="8" >
                        <Accordion.Header>  <span className='digits mx-3'>08</span>When can I withdraw my earnings?                        </Accordion.Header>
                          <Accordion.Body>
                          You can withdraw your earnings at any time from "Purchased NFTs" tab by clicking on CLAIM Button when it's available.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="9" >
                        <Accordion.Header>  <span className='digits mx-3'>09</span>What will happen to my NFT after 1 year?</Accordion.Header>
                          <Accordion.Body>
                          After 1 year, you can either sell it on the public market at the current price, or sell it back to us at the initial amount in $ you buyed.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="10" >
                        <Accordion.Header>  <span className='digits mx-3'>10</span>What happens if I want to sell my NFT earlier?</Accordion.Header>
                          <Accordion.Body>
                          You can sell your NFT on our Market at any time, but at a loss of at least 25%.
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>

                      <div className='acordingouterdiv my-3'>
                        <Accordion.Item eventKey="11" >
                        <Accordion.Header>  <span className='digits mx-3'>11</span>How can I contact support?</Accordion.Header>
                          <Accordion.Body>
                          If you have any questions about your NFT or about the company, please click on the Telegram button that scrolls across the site or here <strong>@EstateSliceNFT</strong>, or write to us at these addresses, and an agent will get back to you: <a className="link-color" href="mailto:contact@estateslicenft.com">contact@estateslicenft.com</a>, <a className="link-color" href="mailto:info@estateslicenft.com">info@estateslicenft.com</a>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>


                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Faqs;
