import React, { useState } from "react";
import "./login.scss";
import { Link, useHistory, useNavigate } from "react-router-dom";
import axios from "axios";
import Environment from "../../utils/Environment";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import Sucessmodal from "../../utils/Sucessmodal";
import { Modal } from "react-bootstrap";


const Login = () => {

  const navigate = useNavigate();


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    const payload = { email, password };

    try {
      const response = await axios.post(`${Environment.API_URL}auth/users/signin`, payload);
      // console.log('Login Successful:', response.data);
      console.log('Login Successful: response.data.user?.referalBy?.walletAddress', response?.data?.data?.accessToken);
      // setSuccess('Login successful!');
      // Save token to localStorage or handle accordingly
      localStorage.setItem('authToken', response?.data?.data?.accessToken);
      localStorage.setItem('referalAddress', response.data?.data?.user?.referalBy?.walletAddress ? response.data?.data?.user?.referalBy?.walletAddress : "0x0000000000000000000000000000000000000000");
      localStorage?.setItem("profileImage", response.data?.data?.user?.profileImage);
      localStorage?.setItem("profileImage", response.data?.data?.user?.profileImage);
      localStorage?.setItem("userName", response.data?.data?.user?.name ? response.data?.data?.user?.name : "");
      // localStorage?.setItem("userId", response.data?.data?.user?.name ? response.data?.data?.user?.name : "");

      // toast?.success("Login");
      handleShow();
      // navigate('/');
    } catch (err) {
      console.log(err.response?.data?.message, "erfref");

      toast?.error(err.response?.data?.message);
      setError(err || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  // const handleForgotPasswordClick = () => {
  //   if (!email) {
  //     // If email is not present, show toast notification
  //     toast.error("Please enter your email to continue!");
  //   } else {
  //     // If email exists, navigate to Forgot Password page with the email state
  //     navigate({
  //       pathname: "/Forgotpassword",
  //       state: { email: email },
  //     });
  //   }
  // };

  return (
    <>
      {loading && <Loader />}

      <section className="mainlogin">
        {/* Right Side: Image */}
        <div className="login-image">
          
        </div>
        {/* Left Side: Login Form */}
        <div className="login-form">
          <a href="/">
          <svg width="209" height="40" viewBox="0 0 209 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61.8073 13.4633V18.9156H68.2217V21.4011H61.8073V27.0939H69.0235V29.5795H58.7605V10.9778H69.0235V13.4633H61.8073Z" fill="#10312B" />
            <path d="M78.1216 29.82C76.9635 29.82 75.9211 29.6151 74.9946 29.2053C74.0859 28.7777 73.3643 28.2075 72.8297 27.4948C72.2952 26.7643 72.0101 25.9536 71.9745 25.0627H75.1282C75.1817 25.6863 75.4757 26.2119 76.0102 26.6395C76.5626 27.0493 77.2485 27.2542 78.0681 27.2542C78.9234 27.2542 79.5827 27.0939 80.0459 26.7732C80.527 26.4346 80.7675 26.007 80.7675 25.4903C80.7675 24.9379 80.5003 24.5281 79.9657 24.2609C79.449 23.9936 78.6205 23.6996 77.4802 23.3789C76.3755 23.076 75.4757 22.782 74.7808 22.4969C74.0859 22.2118 73.4801 21.7753 72.9634 21.1873C72.4645 20.5993 72.215 19.8243 72.215 18.8621C72.215 18.0781 72.4467 17.3654 72.9099 16.724C73.3732 16.0647 74.0324 15.548 74.8877 15.1738C75.7608 14.7997 76.7585 14.6126 77.8811 14.6126C79.5559 14.6126 80.9012 15.0402 81.9168 15.8955C82.9502 16.7329 83.5026 17.8821 83.5738 19.3432H80.527C80.4735 18.6839 80.2063 18.1583 79.7252 17.7663C79.2441 17.3743 78.5938 17.1783 77.7742 17.1783C76.9724 17.1783 76.3576 17.3298 75.93 17.6327C75.5024 17.9356 75.2886 18.3365 75.2886 18.8354C75.2886 19.2274 75.4311 19.557 75.7162 19.8243C76.0013 20.0915 76.3487 20.3053 76.7585 20.4657C77.1684 20.6082 77.7742 20.7953 78.576 21.027C79.645 21.312 80.5181 21.606 81.1952 21.9089C81.89 22.194 82.4869 22.6216 82.9858 23.1918C83.4847 23.762 83.7431 24.5192 83.7609 25.4636C83.7609 26.301 83.5293 27.0493 83.066 27.7086C82.6028 28.3679 81.9435 28.8846 81.0883 29.2587C80.2508 29.6329 79.2619 29.82 78.1216 29.82Z" fill="#10312B" />
            <path d="M90.6077 17.3387V25.4903C90.6077 26.0426 90.7324 26.4435 90.9819 26.693C91.2492 26.9246 91.6946 27.0404 92.3182 27.0404H94.1891V29.5795H91.7837C90.4117 29.5795 89.3605 29.2587 88.6299 28.6173C87.8994 27.9759 87.5342 26.9335 87.5342 25.4903V17.3387H85.7969V14.8531H87.5342V11.1916H90.6077V14.8531H94.1891V17.3387H90.6077Z" fill="#10312B" />
            <path d="M95.9816 22.1495C95.9816 20.6706 96.2845 19.361 96.8903 18.2207C97.514 17.0803 98.3514 16.1984 99.4026 15.5747C100.472 14.9333 101.648 14.6126 102.931 14.6126C104.089 14.6126 105.095 14.8442 105.951 15.3075C106.824 15.7529 107.519 16.3142 108.035 16.9912V14.8531H111.109V29.5795H108.035V27.3879C107.519 28.0828 106.815 28.6618 105.924 29.1251C105.033 29.5884 104.017 29.82 102.877 29.82C101.612 29.82 100.454 29.4993 99.4026 28.8578C98.3514 28.1986 97.514 27.2899 96.8903 26.1317C96.2845 24.9558 95.9816 23.6283 95.9816 22.1495ZM108.035 22.2029C108.035 21.1873 107.822 20.3053 107.394 19.557C106.984 18.8087 106.441 18.2385 105.764 17.8465C105.086 17.4545 104.356 17.2585 103.572 17.2585C102.788 17.2585 102.057 17.4545 101.38 17.8465C100.703 18.2207 100.151 18.7819 99.7234 19.5303C99.3135 20.2608 99.1086 21.1339 99.1086 22.1495C99.1086 23.1651 99.3135 24.056 99.7234 24.8221C100.151 25.5883 100.703 26.1763 101.38 26.5861C102.075 26.9781 102.806 27.1741 103.572 27.1741C104.356 27.1741 105.086 26.9781 105.764 26.5861C106.441 26.1941 106.984 25.6239 107.394 24.8756C107.822 24.1094 108.035 23.2185 108.035 22.2029Z" fill="#10312B" />
            <path d="M118.665 17.3387V25.4903C118.665 26.0426 118.79 26.4435 119.04 26.693C119.307 26.9246 119.752 27.0404 120.376 27.0404H122.247V29.5795H119.841C118.469 29.5795 117.418 29.2587 116.688 28.6173C115.957 27.9759 115.592 26.9335 115.592 25.4903V17.3387H113.855V14.8531H115.592V11.1916H118.665V14.8531H122.247V17.3387H118.665Z" fill="#10312B" />
            <path d="M138.552 21.8555C138.552 22.4078 138.516 22.9067 138.445 23.3522H127.193C127.282 24.5281 127.719 25.4725 128.503 26.1852C129.287 26.8979 130.249 27.2542 131.389 27.2542C133.028 27.2542 134.187 26.5683 134.864 25.1963H138.151C137.706 26.5504 136.895 27.6641 135.719 28.5371C134.561 29.3924 133.117 29.82 131.389 29.82C129.982 29.82 128.716 29.5082 127.594 28.8846C126.489 28.2431 125.616 27.3522 124.975 26.2119C124.351 25.0538 124.039 23.7174 124.039 22.2029C124.039 20.6884 124.342 19.361 124.948 18.2207C125.572 17.0625 126.436 16.1716 127.54 15.548C128.663 14.9244 129.946 14.6126 131.389 14.6126C132.779 14.6126 134.017 14.9155 135.104 15.5213C136.191 16.1271 137.037 16.9823 137.643 18.087C138.249 19.1739 138.552 20.4301 138.552 21.8555ZM135.371 20.8933C135.354 19.7708 134.953 18.871 134.169 18.1939C133.385 17.5169 132.414 17.1783 131.255 17.1783C130.204 17.1783 129.304 17.5169 128.556 18.1939C127.808 18.8532 127.362 19.753 127.22 20.8933H135.371Z" fill="#10312B" />
            <path d="M154.613 29.7665C153.366 29.7665 152.244 29.5527 151.246 29.1251C150.248 28.6797 149.464 28.056 148.894 27.2542C148.324 26.4524 148.039 25.517 148.039 24.448H151.299C151.37 25.2498 151.682 25.909 152.235 26.4257C152.805 26.9424 153.598 27.2008 154.613 27.2008C155.665 27.2008 156.484 26.9513 157.072 26.4524C157.66 25.9357 157.954 25.2765 157.954 24.4747C157.954 23.8511 157.767 23.3433 157.393 22.9513C157.036 22.5593 156.582 22.2564 156.03 22.0426C155.495 21.8288 154.747 21.5971 153.785 21.3477C152.573 21.027 151.584 20.7062 150.818 20.3855C150.07 20.047 149.428 19.5303 148.894 18.8354C148.359 18.1405 148.092 17.214 148.092 16.0558C148.092 14.9868 148.359 14.0513 148.894 13.2495C149.428 12.4477 150.177 11.833 151.139 11.4054C152.101 10.9778 153.215 10.764 154.48 10.764C156.279 10.764 157.749 11.2183 158.89 12.127C160.048 13.0179 160.689 14.2473 160.814 15.8153H157.446C157.393 15.1382 157.072 14.5591 156.484 14.078C155.896 13.597 155.121 13.3564 154.159 13.3564C153.286 13.3564 152.573 13.5792 152.021 14.0246C151.468 14.47 151.192 15.1115 151.192 15.9489C151.192 16.5191 151.362 16.9912 151.7 17.3654C152.056 17.7218 152.502 18.0069 153.036 18.2207C153.571 18.4345 154.301 18.6661 155.228 18.9156C156.457 19.2541 157.455 19.5926 158.221 19.9312C159.005 20.2697 159.665 20.7953 160.199 21.508C160.751 22.2029 161.028 23.1384 161.028 24.3143C161.028 25.2587 160.769 26.1495 160.253 26.987C159.754 27.8244 159.014 28.5015 158.034 29.0182C157.072 29.5171 155.932 29.7665 154.613 29.7665Z" fill="#10312B" />
            <path d="M167.67 9.8018V29.5795H164.623V9.8018H167.67Z" fill="#10312B" />
            <path d="M173.22 12.9021C172.668 12.9021 172.204 12.715 171.83 12.3408C171.456 11.9666 171.269 11.5034 171.269 10.951C171.269 10.3987 171.456 9.93543 171.83 9.56126C172.204 9.18709 172.668 9 173.22 9C173.754 9 174.209 9.18709 174.583 9.56126C174.957 9.93543 175.144 10.3987 175.144 10.951C175.144 11.5034 174.957 11.9666 174.583 12.3408C174.209 12.715 173.754 12.9021 173.22 12.9021ZM174.717 14.8531V29.5795H171.67V14.8531H174.717Z" fill="#10312B" />
            <path d="M177.701 22.2029C177.701 20.6884 178.004 19.361 178.61 18.2207C179.234 17.0625 180.089 16.1716 181.176 15.548C182.263 14.9244 183.51 14.6126 184.917 14.6126C186.699 14.6126 188.169 15.0402 189.327 15.8955C190.503 16.7329 191.296 17.9356 191.706 19.5035H188.419C188.151 18.773 187.724 18.2028 187.136 17.793C186.548 17.3832 185.808 17.1783 184.917 17.1783C183.67 17.1783 182.672 17.6238 181.924 18.5147C181.194 19.3877 180.828 20.6172 180.828 22.2029C180.828 23.7887 181.194 25.027 181.924 25.9179C182.672 26.8088 183.67 27.2542 184.917 27.2542C186.681 27.2542 187.848 26.4792 188.419 24.929H191.706C191.278 26.4257 190.477 27.6195 189.301 28.5104C188.125 29.3835 186.664 29.82 184.917 29.82C183.51 29.82 182.263 29.5082 181.176 28.8846C180.089 28.2431 179.234 27.3522 178.61 26.2119C178.004 25.0538 177.701 23.7174 177.701 22.2029Z" fill="#10312B" />
            <path d="M208.239 21.8555C208.239 22.4078 208.204 22.9067 208.132 23.3522H196.88C196.97 24.5281 197.406 25.4725 198.19 26.1852C198.974 26.8979 199.936 27.2542 201.077 27.2542C202.716 27.2542 203.874 26.5683 204.551 25.1963H207.838C207.393 26.5504 206.582 27.6641 205.406 28.5371C204.248 29.3924 202.805 29.82 201.077 29.82C199.669 29.82 198.404 29.5082 197.281 28.8846C196.177 28.2431 195.304 27.3522 194.662 26.2119C194.039 25.0538 193.727 23.7174 193.727 22.2029C193.727 20.6884 194.03 19.361 194.635 18.2207C195.259 17.0625 196.123 16.1716 197.228 15.548C198.35 14.9244 199.633 14.6126 201.077 14.6126C202.466 14.6126 203.705 14.9155 204.792 15.5213C205.878 16.1271 206.725 16.9823 207.331 18.087C207.936 19.1739 208.239 20.4301 208.239 21.8555ZM205.059 20.8933C205.041 19.7708 204.64 18.871 203.856 18.1939C203.072 17.5169 202.101 17.1783 200.943 17.1783C199.892 17.1783 198.992 17.5169 198.244 18.1939C197.495 18.8532 197.05 19.753 196.907 20.8933H205.059Z" fill="#10312B" />
            <path d="M30.8028 24.7178C30.177 25.3822 29.5576 26.0529 28.9253 26.7108C28.7071 26.9387 28.7777 27.2115 28.7777 27.4714C28.7745 31.1429 28.7649 34.8175 28.7873 38.489C28.7905 39.0185 28.7039 39.2175 28.1102 39.2078C25.8925 39.1661 23.6749 39.179 21.4605 39.2014C21.0112 39.2046 20.9053 39.0698 20.9053 38.6366C20.9213 34.2013 20.9181 29.7693 20.9085 25.334C20.9085 25.042 20.9919 24.8398 21.1973 24.628C23.7872 22.006 26.3707 19.3807 28.9446 16.7427C29.2013 16.4795 29.4677 16.3736 29.8304 16.3768C31.6211 16.3897 33.4151 16.364 35.2059 16.3961C35.7226 16.4057 35.8478 16.2388 35.8382 15.7446C35.8093 13.9763 35.8414 12.2047 35.8157 10.4364C35.8093 10.016 35.9409 9.72715 36.2393 9.44794C36.8748 8.8478 37.4749 8.21236 38.1007 7.6058C38.2516 7.45817 38.3703 7.1565 38.6046 7.23673C38.8613 7.32659 38.7233 7.62827 38.7233 7.83366C38.7233 13.344 38.7137 18.8512 38.7137 24.3616C38.7137 29.0857 38.7169 33.813 38.7426 38.5371C38.7458 39.0538 38.6271 39.2143 38.0879 39.2078C35.9601 39.1758 33.8324 39.1725 31.7046 39.2078C31.143 39.2175 31.0563 39.0249 31.0563 38.5243C31.0659 34.2013 31.0499 29.8816 31.0403 25.5587C31.0403 25.3212 31.0403 25.0837 31.0403 24.8494C30.9568 24.8077 30.8798 24.7628 30.8028 24.7178Z" fill="#F6881F" />
            <path d="M18.6717 19.7017C18.6717 25.9727 18.6653 32.2468 18.6846 38.5178C18.6878 39.0698 18.569 39.2207 18.001 39.211C15.8058 39.1693 13.6107 39.179 11.4155 39.195C10.9822 39.1982 10.8442 39.0891 10.8442 38.6366C10.8603 28.3989 10.8635 18.1644 10.8603 7.92673C10.8603 7.63147 10.9598 7.43571 11.1555 7.23352C12.6639 5.69305 14.1691 4.14938 15.6678 2.59607C16.3578 1.8804 17.0286 1.14867 17.7057 0.423371C17.8983 0.217976 18.0973 -0.0708617 18.3893 0.0157895C18.7584 0.128115 18.691 0.506813 18.691 0.818116C18.691 7.11157 18.691 13.4082 18.691 19.7017C18.6878 19.7017 18.6814 19.7017 18.6717 19.7017Z" fill="#10312B" />
            <path d="M8.53993 12.9204C8.79026 14.1689 8.70682 15.3499 8.62658 16.5213C8.58807 17.0604 8.64584 17.5868 8.64584 18.1195C8.63942 24.9265 8.633 31.7366 8.65547 38.5435C8.65868 39.1212 8.495 39.2303 7.95263 39.2207C5.735 39.1854 3.51737 39.1886 1.30295 39.2111C0.853645 39.2143 0.757365 39.0827 0.760575 38.6559C0.776621 32.7893 0.776621 26.9194 0.776621 21.0528C0.776621 20.7832 0.792667 20.5586 1.01732 20.3371C3.44997 17.927 5.86658 15.5007 8.29282 13.0777C8.33775 13.036 8.39872 13.0103 8.53993 12.9204Z" fill="#10312B" />
            <path d="M20.9023 14.8363C25.2574 10.1411 29.9397 6.03642 34.1953 1.50488C34.2498 1.53377 34.3044 1.56265 34.3622 1.59474C34.3622 1.78409 34.3622 1.97344 34.3622 2.16279C34.3622 6.17121 34.3525 10.1796 34.375 14.1849C34.3782 14.7048 34.2563 14.8556 33.7171 14.8524C29.5097 14.8267 25.3023 14.8363 20.9023 14.8363Z" fill="#10312B" />
          </svg>
          </a>
          <h2>Log into your account</h2>
          <div className="forremsbs" >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"

              />
            </div>
            {/* <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
              />
              <span
                className="eyeonlyyysss"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "👁️" : "👁️‍🗨️"}
              </span>
            </div> */}
            <div className="form-group onlyforpositoninput" >
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"} // Toggle input type
                id="password"
                placeholder="Enter your password"
                value={password}
                className=""
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
                style={{ paddingRight: "40px" }} // Space for the eye icon
              />
              <span
                className="eyeonlyyysss"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "👁️" : "👁️‍🗨️"}
              </span>
              {error.password && (
                <span className="error text-danger">{error.password}</span>
              )}
            </div>
            <div className="form-options">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked"

                />
                <label
                  className="form-check-label keepmelogin"
                  htmlFor="flexCheckChecked"
                >
                  Keep me logged in
                </label>
              </div>
              {/* <Link to={{ pathname: "/Forgotpassword", state: { email: email } }}> */}
              {/* <p className="forget-password" >
              Forgot Password
            </p> */}
              {/* </Link>  */}
              <a href="https://my.estateslicenft.com/Forgotpassword"
                    className="forget-password"
                    // onClick={handleForgotPasswordClick}
                  >
                    Forgot Password
                  </a>
            </div>
            {/* <Link to="/admin/dashboard"> */}
            <button className="btn-login" type="submit" onClick={() => handleSubmit()}>
              Login
            </button>
            {/* <Link to="https://my.estateslicenft.com/signup"> */}
                  <p
                    style={{
                      color: "#10312B",

                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "100%",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    Don't have an account?{" "}
                    <a
                      href="https://my.estateslicenft.com/signup"
                      style={{
                        color: "#FF8A00",

                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "100%",
                        textDecoration: "underline",
                        textDecorationLine: "solid",
                        textDecorationSkipInk: "none",
                        textUnderlineOffset: "auto",
                      }}
                    >
                      Go to SignUp
                    </a>
                  </p>
                {/* </Link> */}
            {/* </Link> */}
          </div>
        </div>
      </section>



      <Modal className="ambmodalmain" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="myyyydiv text-center">
            <svg className="imgsvg" xmlns="http://www.w3.org/2000/svg" width="213" height="214" viewBox="0 0 213 214" fill="none">
              <path d="M159.75 160.25C189.159 130.841 189.159 83.1593 159.75 53.7501C130.341 24.3409 82.6593 24.3409 53.2501 53.7501C23.8409 83.1592 23.8409 130.841 53.2501 160.25C82.6593 189.659 130.341 189.659 159.75 160.25Z" fill="#3FC700" />
              <path d="M193.678 84.0903C189.64 68.6834 181.534 54.567 170.235 43.2675C153.212 26.2446 130.578 16.87 106.504 16.87C82.4294 16.87 59.7956 26.2446 42.7727 43.2675C31.4744 54.5658 23.3686 68.6816 19.3306 84.0885L16.2642 83.2852C20.4442 67.3344 28.8362 52.722 40.5318 41.0267C58.1529 23.4047 81.5829 13.6999 106.504 13.6999C131.425 13.6999 154.855 23.4047 172.476 41.0267C184.172 52.7223 192.564 67.3362 196.744 83.287L193.678 84.0903Z" fill="#3FC700" />
              <path d="M200.903 82.0372C201.661 85.2393 199.678 88.4502 196.476 89.2071C193.273 89.9644 190.063 87.9815 189.306 84.7794C188.549 81.5773 190.531 78.3665 193.733 77.6095C196.936 76.8523 200.146 78.8349 200.903 82.0372Z" fill="#3FC700" />
              <path d="M12.1046 82.0372C11.3473 85.2393 13.3302 88.4502 16.5323 89.2071C19.7347 89.9644 22.9452 87.9815 23.7025 84.7794C24.4594 81.5773 22.4768 78.3665 19.2747 77.6095C16.0721 76.8523 12.8615 78.8349 12.1046 82.0372Z" fill="#3FC700" />
              <path d="M106.504 200.297C81.5835 200.297 58.1535 190.592 40.5315 172.97C29.1012 161.54 20.8049 147.29 16.5386 131.758L19.5948 130.919C23.7152 145.921 31.731 159.687 42.7721 170.73C59.7959 187.753 82.4296 197.127 106.504 197.127C130.577 197.127 153.21 187.753 170.234 170.73C181.277 159.687 189.291 145.921 193.411 130.92L196.468 131.76C192.202 147.29 183.906 161.54 172.475 172.97C154.853 190.592 131.424 200.297 106.504 200.297Z" fill="#3FC700" />
              <path d="M12.3885 133.053C11.5963 129.859 13.544 126.627 16.7376 125.835C19.9311 125.043 23.1634 126.991 23.9556 130.185C24.7477 133.378 22.8004 136.61 19.6065 137.403C16.4124 138.194 13.1804 136.247 12.3885 133.053Z" fill="#3FC700" />
              <path d="M200.619 133.053C201.411 129.859 199.464 126.627 196.27 125.835C193.076 125.043 189.844 126.991 189.052 130.185C188.26 133.378 190.207 136.61 193.401 137.403C196.595 138.194 199.827 136.247 200.619 133.053Z" fill="#3FC700" />
              <path d="M138.177 71.8188L92.7971 117.199L74.8309 99.2324L62.3413 111.722L92.7971 142.178L150.667 84.3083L138.177 71.8188Z" fill="white" />
            </svg>
            <h1>Congratulations</h1>
            <p>
              Login successful
            </p>
            <button onClick={() => navigate('/')} className="btn btn-primary">Done</button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Login;



