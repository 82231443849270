import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { NftPurchaseContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const FetchMintedNFT = () => {

    const { account } = useWeb3React();
    const web3 = useWeb3();
    const tokenAddress = Environment.nftPurchase;
    const contract = NftPurchaseContract(tokenAddress, web3);

    const fetchMintedNFThHook = useCallback(
        async (index) => {
            try {
                const details = await contract.methods.nftsMintedByCategoryIndex(index).call({
                    from: account
                });
                console.log(details, "res in FetchMintedNFT");

                return details;
            } catch (error) {
                console.log(error, "error in FetchMintedNFT");

                throw error;
            }
        },
        [contract, account]
    );
    return { fetchMintedNFThHook: fetchMintedNFThHook };
};
export default FetchMintedNFT;