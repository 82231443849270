import React from "react";
import "./email.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbarmint from "../landing/headermint/Navbarmint";
import { Link } from "react-router-dom";

const Email = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="  leftrightmarginonly">
          <section className="emailhead">
            <Navbarmint />
            <div className="joinmain">
              <p>Estate Slice Property NFT</p>
              {/* <img src="\assets\backkkkimg.svg" className="backkkimg"/> */}
              <h1>First, We’re gonna Need your email</h1>
              <input
                placeholder="Enter your email address"
                className="innnnnput"
                type="text"
                id="fname"
                name="fname"
              />
              <div>
                <label class="container">
                  I agree to the terms of use
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <Link to="/connect">
                <button>Join Now</button>
              </Link>

              <div className="myyyyyyyyyy">
                <img src="\assets\imgleft.svg" className="imgleftt" />
              </div>

              <img src="\assets\imgright.svg" className="imgright" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Email;
