import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import { NftPurchaseContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BuyNFT = () => {

    const { account } = useWeb3React();
    const web3 = useWeb3();
    const tokenAddress = Environment.nftPurchase;
    const contract = NftPurchaseContract(tokenAddress, web3);


    const BuyNFTHook = useCallback(
        async (catIndex, nftPrice, nftCount, proofs, _referrer) => {
            let costPerNft = nftPrice * nftCount;
            // const costPerNftInWei = web3.utils.fromWei(costPerNft?.toString(), 'ether');

            console.log('payload okokokok', catIndex, nftCount, costPerNft, proofs);

            try {
                let gasPrice = await web3.eth.getGasPrice();
                gasPrice = parseInt(gasPrice) + 5000000000;
                const gas = await contract.methods.purchaseTokensWhitelist(catIndex, nftCount, proofs, _referrer).estimateGas({
                    from: account, value: costPerNft
                });
                const details = await contract.methods.purchaseTokensWhitelist(catIndex, nftCount, proofs, _referrer).send({
                    from: account,
                    value: costPerNft,
                    gas,
                    gasPrice: gasPrice
                });
                return details;
            } catch (error) {
                console.log(error, "error in white list buy nft");

                throw error;
            }
        },
        [contract, account]
    );
    return { BuyNFTHook: BuyNFTHook };
};
export default BuyNFT;