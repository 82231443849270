import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import ImageGallery from "react-image-gallery";
import './information.scss';


const Information = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const images = [
        {
            original: "/assets/img1.svg",
            thumbnail: "/assets/img1.svg",
        },
        {
            original: "/assets/img2.svg",
            thumbnail: "/assets/img2.svg",
        },
        {
            original: "/assets/im3.svg",
            thumbnail: "/assets/im3.svg",
        },
        {
            original: "/assets/four.svg",
            thumbnail: "/assets/four.svg",
        },
        // {
        //     original: "/assets/five.svg",
        //     thumbnail: "/assets/five.svg",
        // },
        {
            original: "/assets/six.svg",
            thumbnail: "/assets/six.svg",
        },
        {
            original: "/assets/seven.svg",
            thumbnail: "/assets/seven.svg",
        },
        {
            original: "/assets/eight.svg",
            thumbnail: "/assets/eight.svg",
        }, {
            original: "/assets/nine.svg",
            thumbnail: "/assets/nine.svg",
        },
        // {
        //     original: "/assets/nine.svg",
        //     thumbnail: "/assets/nine.svg",
        // },

        {
            original: "/assets/ten.svg",
            thumbnail: "/assets/ten.svg",
        },

        {
            original: "/assets/tenone.svg",
            thumbnail: "/assets/tenone.svg",
        },

        {
            original: "/assets/tentwo.svg",
            thumbnail: "/assets/tentwo.svg",
        },

        {
            original: "/assets/ten3.svg",
            thumbnail: "/assets/ten3.svg",
        }


    ];
    return (
        <>
            <div className="infor_main" id="information">
                <section className="affiliateprogram_info" >
                    <div className="custom-container">
                        <div className="Bbenefits">
                            <div className="toperparraa">
                                <p className="commonheading ">

                                    NFT Collection
                                </p>

                                <h2 className="besthngd">Estate Slice NFT Collection</h2>
                                <p className="seccsparaa">
                                    includes iconic complexes with towers linked by 300-foot skybridges. Built using nearly a million square meters of glass, marble, and concrete, these structures are truly remarkable.

                                </p>
                            </div>
                        </div>
                        <div className="image-gallery-container">
                            {/* <img src='\assets\imggallery.svg' alt='img' className='img-fluid editimg' onClick={handleShow} /> */}
                            <ImageGallery items={images} />
                        </div>


                        
                    </div>
                </section>

            </div>

        </>
    );
};

export default Information;
