import React, { useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../header/Navbar";
import Spline from "@splinetool/react-spline";
import { HashLink } from "react-router-hash-link";

const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="important_div">
        <section className="main-banner">
          <div className="container-fluid onlysformobile">
            <Navbar />
            <div className="custom-container">
              <div className="onlyforflex_s">
                <div className="maincenterdiv">
                  <button className="pprotocoldd_new">
                    Estate Slice Property NFT
                  </button>
                  <h2 className="parafirstcc">
                    Where Modern NFTs Meet Iconic Homes
                  </h2>
                  <p className="parafirstff">
                    Invest in luxury real estate and enjoy fractional ownership,
                    easy transactions, and increased liquidity in premium
                    properties
                  </p>
                  <HashLink to="#properties">
                    <button className="mintbutton">Mint Your NFT</button>
                  </HashLink>
                </div>
                {/* <div className="leftside_3D">
                  <Spline scene="https://prod.spline.design/5w2xqO7VDfzp73FC/scene.splinecode" className="img-fluid" />
                  <Spline scene="https://prod.spline.design/sIRTOduz8wPVRGjn/scene.splinecode" />
                  
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Banner;
