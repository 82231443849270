import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./buynow.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { HashLink } from "react-router-hash-link";

const Buynow = () => {
  return (
    <>
      <section className="buynow_main" id="about">
        <div className="container-fluid">
          <div className="mainbanner_infoss">
            <div className="row">
              <div className="col-xl-7 col-sm-12 m-auto">
                <div className="newinoeart">
                  <h2 className="apple">
                    Be one of the first to enter Estate Slice
                  </h2>
                  <HashLink to="#properties">
                    <button className="buynowbtn">Mint Your NFT</button>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Buynow;
