import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import Button from 'react-bootstrap/Button';
import Offcanvas from "react-bootstrap/Offcanvas";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { setupNetwork } from "../../../utils/wallet";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage?.getItem("authToken");

  const { login, logout } = useAuth();
  let { account, chainId } = useWeb3React();

  const [isModalOpen, setModalOpen] = useState(false);
  const [showsidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  console.log(showsidebar, "showsidebar");

  const handleClose = () => {
    setModalOpen(false);
    handleCloseSidebar();
  };

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  // Function to handle clicks outside the modal content
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      let res = await setupNetwork();
      console.log(res, "res in setupNetwork");

      if (res) {
        login("injected");
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", "true");
        handleClose();
      }
    }
  };

  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
      handleClose();
    } else {
      try {
        handleClose();
        login("walletconnect");
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };

  const logoutHandle = async () => {
    const connectorId = window.localStorage.getItem("connectorId");
    await logout(connectorId);
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };

  const logoutUserHandle = () => {
    localStorage?.removeItem("authToken");
    localStorage?.removeItem("referalAddress");
    window?.location?.reload();

    localStorage.removeItem("profileImage");
    localStorage.removeItem("userName");
  };

  useEffect(() => {
    // Update the timer every second
    if (chainId != 11155111) {
      setupNetwork(11155111);
    }
  }, [chainId]);

  return (
    <section className="main-navbar-mobile main-navbar">
      <div className="container-fluid  ">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12  m-auto    nav_boder ">
            <nav className="navbar  navbar-expand-xl ">
              <a
                href="#"
                className=" razaaaaaaaa11111     navbar-brand animate__flash"
              >
                <Link to="/">
                  {/* <img
                    src="\Logo\Logo.png"
                    alt="img"
                    width={'204px'}
                    className="img-fluid animate__animated animate__bounce"
                  /> */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="163"
                    height="31"
                    viewBox="0 0 163 31"
                    fill="none"
                  >
                    <path
                      d="M47.9598 10.5771V14.8604H52.999V16.8132H47.9598V21.2855H53.6289V23.2382H45.5661V8.62433H53.6289V10.5771H47.9598Z"
                      fill="white"
                    />
                    <path
                      d="M60.7766 23.4272C59.8667 23.4272 59.0479 23.2662 58.32 22.9443C57.6061 22.6083 57.0392 22.1604 56.6192 21.6005C56.1993 21.0266 55.9753 20.3896 55.9473 19.6897H58.425C58.467 20.1797 58.6979 20.5926 59.1179 20.9286C59.5518 21.2505 60.0907 21.4115 60.7346 21.4115C61.4065 21.4115 61.9245 21.2855 62.2884 21.0336C62.6663 20.7676 62.8553 20.4316 62.8553 20.0257C62.8553 19.5918 62.6454 19.2698 62.2254 19.0598C61.8195 18.8499 61.1686 18.6189 60.2727 18.3669C59.4048 18.129 58.6979 17.898 58.152 17.674C57.6061 17.4501 57.1301 17.1071 56.7242 16.6452C56.3323 16.1833 56.1363 15.5743 56.1363 14.8184C56.1363 14.2025 56.3183 13.6426 56.6822 13.1387C57.0462 12.6208 57.5641 12.2148 58.236 11.9209C58.9219 11.6269 59.7058 11.4799 60.5876 11.4799C61.9035 11.4799 62.9603 11.8159 63.7582 12.4878C64.5701 13.1457 65.004 14.0486 65.06 15.1964H62.6663C62.6244 14.6785 62.4144 14.2655 62.0364 13.9576C61.6585 13.6496 61.1476 13.4956 60.5037 13.4956C59.8737 13.4956 59.3908 13.6146 59.0549 13.8526C58.7189 14.0906 58.5509 14.4055 58.5509 14.7975C58.5509 15.1054 58.6629 15.3644 58.8869 15.5743C59.1109 15.7843 59.3838 15.9523 59.7058 16.0783C60.0277 16.1903 60.5037 16.3372 61.1336 16.5192C61.9734 16.7432 62.6593 16.9741 63.1913 17.2121C63.7372 17.4361 64.2061 17.772 64.5981 18.22C64.99 18.6679 65.193 19.2628 65.207 20.0047C65.207 20.6626 65.025 21.2505 64.6611 21.7685C64.2971 22.2864 63.7792 22.6923 63.1073 22.9863C62.4494 23.2802 61.6725 23.4272 60.7766 23.4272Z"
                      fill="white"
                    />
                    <path
                      d="M70.586 13.6216V20.0257C70.586 20.4596 70.684 20.7746 70.8799 20.9706C71.0899 21.1525 71.4399 21.2435 71.9298 21.2435H73.3996V23.2382H71.5099C70.432 23.2382 69.6061 22.9863 69.0322 22.4823C68.4583 21.9784 68.1713 21.1595 68.1713 20.0257V13.6216H66.8065V11.6689H68.1713V8.79231H70.586V11.6689H73.3996V13.6216H70.586Z"
                      fill="white"
                    />
                    <path
                      d="M74.8079 17.4011C74.8079 16.2392 75.0458 15.2104 75.5218 14.3145C76.0117 13.4186 76.6696 12.7257 77.4955 12.2358C78.3354 11.7319 79.2592 11.4799 80.2671 11.4799C81.1769 11.4799 81.9678 11.6619 82.6397 12.0258C83.3256 12.3758 83.8716 12.8167 84.2775 13.3487V11.6689H86.6922V23.2382H84.2775V21.5165C83.8716 22.0624 83.3186 22.5173 82.6187 22.8813C81.9188 23.2452 81.121 23.4272 80.2251 23.4272C79.2312 23.4272 78.3214 23.1753 77.4955 22.6713C76.6696 22.1534 76.0117 21.4395 75.5218 20.5296C75.0458 19.6058 74.8079 18.5629 74.8079 17.4011ZM84.2775 17.4431C84.2775 16.6452 84.1095 15.9523 83.7736 15.3644C83.4516 14.7765 83.0247 14.3285 82.4928 14.0206C81.9608 13.7126 81.3869 13.5586 80.771 13.5586C80.1551 13.5586 79.5812 13.7126 79.0493 14.0206C78.5173 14.3145 78.0834 14.7555 77.7474 15.3434C77.4255 15.9173 77.2645 16.6032 77.2645 17.4011C77.2645 18.199 77.4255 18.8989 77.7474 19.5008C78.0834 20.1027 78.5173 20.5646 79.0493 20.8866C79.5952 21.1945 80.1691 21.3485 80.771 21.3485C81.3869 21.3485 81.9608 21.1945 82.4928 20.8866C83.0247 20.5786 83.4516 20.1307 83.7736 19.5428C84.1095 18.9409 84.2775 18.241 84.2775 17.4431Z"
                      fill="white"
                    />
                    <path
                      d="M92.6287 13.6216V20.0257C92.6287 20.4596 92.7267 20.7746 92.9227 20.9706C93.1327 21.1525 93.4826 21.2435 93.9725 21.2435H95.4423V23.2382H93.5526C92.4748 23.2382 91.6489 22.9863 91.075 22.4823C90.501 21.9784 90.2141 21.1595 90.2141 20.0257V13.6216H88.8493V11.6689H90.2141V8.79231H92.6287V11.6689H95.4423V13.6216H92.6287Z"
                      fill="white"
                    />
                    <path
                      d="M108.252 17.1701C108.252 17.604 108.224 17.996 108.168 18.3459H99.3283C99.3982 19.2698 99.7412 20.0117 100.357 20.5716C100.973 21.1315 101.729 21.4115 102.625 21.4115C103.913 21.4115 104.822 20.8726 105.354 19.7947H107.937C107.587 20.8586 106.95 21.7335 106.026 22.4194C105.116 23.0913 103.983 23.4272 102.625 23.4272C101.519 23.4272 100.525 23.1822 99.6432 22.6923C98.7753 22.1884 98.0894 21.4885 97.5855 20.5926C97.0956 19.6828 96.8506 18.6329 96.8506 17.4431C96.8506 16.2532 97.0886 15.2104 97.5645 14.3145C98.0544 13.4047 98.7333 12.7048 99.6012 12.2148C100.483 11.7249 101.491 11.4799 102.625 11.4799C103.717 11.4799 104.689 11.7179 105.543 12.1938C106.397 12.6698 107.062 13.3417 107.538 14.2095C108.014 15.0634 108.252 16.0503 108.252 17.1701ZM105.753 16.4142C105.739 15.5323 105.424 14.8254 104.808 14.2935C104.193 13.7616 103.43 13.4956 102.52 13.4956C101.694 13.4956 100.987 13.7616 100.399 14.2935C99.8112 14.8114 99.4612 15.5183 99.3492 16.4142H105.753Z"
                      fill="white"
                    />
                    <path
                      d="M120.87 23.3852C119.89 23.3852 119.008 23.2172 118.225 22.8813C117.441 22.5313 116.825 22.0414 116.377 21.4115C115.929 20.7816 115.705 20.0467 115.705 19.2068H118.267C118.323 19.8367 118.568 20.3547 119.001 20.7606C119.449 21.1665 120.072 21.3695 120.87 21.3695C121.696 21.3695 122.34 21.1735 122.802 20.7816C123.264 20.3757 123.495 19.8577 123.495 19.2278C123.495 18.7379 123.348 18.3389 123.054 18.031C122.774 17.723 122.417 17.4851 121.983 17.3171C121.563 17.1491 120.975 16.9671 120.219 16.7712C119.267 16.5192 118.491 16.2672 117.889 16.0153C117.301 15.7493 116.797 15.3434 116.377 14.7975C115.957 14.2515 115.747 13.5236 115.747 12.6138C115.747 11.7739 115.957 11.039 116.377 10.4091C116.797 9.77917 117.385 9.29624 118.141 8.96029C118.896 8.62433 119.771 8.45636 120.765 8.45636C122.179 8.45636 123.334 8.81331 124.23 9.5272C125.14 10.2271 125.644 11.193 125.741 12.4248H123.096C123.054 11.8929 122.802 11.4379 122.34 11.06C121.878 10.682 121.269 10.4931 120.513 10.4931C119.827 10.4931 119.267 10.668 118.833 11.018C118.4 11.3679 118.183 11.8719 118.183 12.5298C118.183 12.9777 118.316 13.3487 118.582 13.6426C118.861 13.9226 119.211 14.1465 119.631 14.3145C120.051 14.4825 120.625 14.6645 121.353 14.8604C122.319 15.1264 123.103 15.3924 123.705 15.6583C124.321 15.9243 124.839 16.3372 125.259 16.8972C125.692 17.4431 125.909 18.178 125.909 19.1018C125.909 19.8437 125.706 20.5436 125.301 21.2015C124.909 21.8594 124.328 22.3914 123.558 22.7973C122.802 23.1892 121.906 23.3852 120.87 23.3852Z"
                      fill="white"
                    />
                    <path
                      d="M131.128 7.70047V23.2382H128.734V7.70047H131.128Z"
                      fill="white"
                    />
                    <path
                      d="M135.488 10.1361C135.054 10.1361 134.69 9.98914 134.396 9.69518C134.102 9.40122 133.955 9.03728 133.955 8.60334C133.955 8.1694 134.102 7.80545 134.396 7.51149C134.69 7.21754 135.054 7.07056 135.488 7.07056C135.908 7.07056 136.265 7.21754 136.559 7.51149C136.853 7.80545 137 8.1694 137 8.60334C137 9.03728 136.853 9.40122 136.559 9.69518C136.265 9.98914 135.908 10.1361 135.488 10.1361ZM136.664 11.6689V23.2382H134.27V11.6689H136.664Z"
                      fill="white"
                    />
                    <path
                      d="M139.009 17.4431C139.009 16.2532 139.247 15.2104 139.723 14.3145C140.212 13.4047 140.884 12.7048 141.738 12.2148C142.592 11.7249 143.572 11.4799 144.678 11.4799C146.078 11.4799 147.232 11.8159 148.142 12.4878C149.066 13.1457 149.689 14.0906 150.011 15.3224H147.428C147.218 14.7485 146.883 14.3005 146.421 13.9786C145.959 13.6566 145.378 13.4956 144.678 13.4956C143.698 13.4956 142.914 13.8456 142.326 14.5455C141.752 15.2314 141.465 16.1973 141.465 17.4431C141.465 18.6889 141.752 19.6618 142.326 20.3617C142.914 21.0616 143.698 21.4115 144.678 21.4115C146.064 21.4115 146.98 20.8026 147.428 19.5848H150.011C149.675 20.7606 149.045 21.6985 148.121 22.3984C147.197 23.0843 146.05 23.4272 144.678 23.4272C143.572 23.4272 142.592 23.1822 141.738 22.6923C140.884 22.1884 140.212 21.4885 139.723 20.5926C139.247 19.6828 139.009 18.6329 139.009 17.4431Z"
                      fill="white"
                    />
                    <path
                      d="M163 17.1701C163 17.604 162.972 17.996 162.916 18.3459H154.076C154.146 19.2698 154.489 20.0117 155.105 20.5716C155.721 21.1315 156.477 21.4115 157.373 21.4115C158.661 21.4115 159.57 20.8726 160.102 19.7947H162.685C162.335 20.8586 161.698 21.7335 160.774 22.4194C159.864 23.0913 158.731 23.4272 157.373 23.4272C156.267 23.4272 155.273 23.1822 154.391 22.6923C153.523 22.1884 152.837 21.4885 152.334 20.5926C151.844 19.6828 151.599 18.6329 151.599 17.4431C151.599 16.2532 151.837 15.2104 152.313 14.3145C152.802 13.4047 153.481 12.7048 154.349 12.2148C155.231 11.7249 156.239 11.4799 157.373 11.4799C158.465 11.4799 159.438 11.7179 160.291 12.1938C161.145 12.6698 161.81 13.3417 162.286 14.2095C162.762 15.0634 163 16.0503 163 17.1701ZM160.501 16.4142C160.487 15.5323 160.172 14.8254 159.556 14.2935C158.941 13.7616 158.178 13.4956 157.268 13.4956C156.442 13.4956 155.735 13.7616 155.147 14.2935C154.559 14.8114 154.209 15.5183 154.097 16.4142H160.501Z"
                      fill="white"
                    />
                    <path
                      d="M23.602 19.4188C23.1103 19.9407 22.6237 20.4677 22.127 20.9846C21.9556 21.1636 22.0111 21.3779 22.0111 21.5821C22.0085 24.4665 22.001 27.3534 22.0186 30.2377C22.0211 30.6538 21.9531 30.8101 21.4866 30.8025C19.7444 30.7697 18.0022 30.7798 16.2625 30.7975C15.9095 30.8 15.8263 30.6941 15.8263 30.3537C15.8389 26.8693 15.8364 23.3874 15.8288 19.9029C15.8288 19.6735 15.8944 19.5146 16.0557 19.3482C18.0904 17.2883 20.1201 15.2259 22.1422 13.1534C22.3439 12.9466 22.5531 12.8634 22.838 12.866C24.2449 12.876 25.6543 12.8559 27.0612 12.8811C27.4672 12.8887 27.5655 12.7575 27.5579 12.3693C27.5352 10.98 27.5604 9.58827 27.5403 8.19903C27.5352 7.86874 27.6386 7.64182 27.8731 7.42247C28.3723 6.95098 28.8438 6.45176 29.3354 5.97524C29.4539 5.85926 29.5472 5.62226 29.7313 5.68529C29.933 5.75588 29.8246 5.99289 29.8246 6.15425C29.8246 10.4833 29.817 14.8099 29.817 19.139C29.817 22.8503 29.8195 26.5642 29.8397 30.2756C29.8422 30.6815 29.7489 30.8076 29.3254 30.8025C27.6537 30.7773 25.9821 30.7748 24.3105 30.8025C23.8693 30.8101 23.8012 30.6588 23.8012 30.2655C23.8087 26.8693 23.7961 23.4756 23.7886 20.0794C23.7886 19.8928 23.7886 19.7063 23.7886 19.5222C23.723 19.4894 23.6625 19.4541 23.602 19.4188Z"
                      fill="#F6881F"
                    />
                    <path
                      d="M14.0715 15.4781C14.0715 20.4047 14.0664 25.3339 14.0815 30.2605C14.0841 30.6941 13.9908 30.8126 13.5445 30.8051C11.8199 30.7723 10.0954 30.7799 8.37079 30.7925C8.03041 30.795 7.922 30.7093 7.922 30.3538C7.9346 22.3108 7.93712 14.2704 7.9346 6.22742C7.9346 5.99546 8.01276 5.84166 8.16656 5.68282C9.35158 4.47259 10.5341 3.25984 11.7115 2.03953C12.2536 1.47728 12.7805 0.902425 13.3125 0.33261C13.4638 0.171247 13.6201 -0.0556706 13.8496 0.0124046C14.1395 0.10065 14.0866 0.398164 14.0866 0.64273C14.0866 5.58701 14.0866 10.5338 14.0866 15.4781C14.0841 15.4781 14.079 15.4781 14.0715 15.4781Z"
                      fill="white"
                    />
                    <path
                      d="M6.1117 10.1506C6.30836 11.1314 6.24281 12.0593 6.17977 12.9795C6.14952 13.4031 6.1949 13.8166 6.1949 14.2351C6.18986 19.5828 6.18482 24.933 6.20247 30.2807C6.20499 30.7346 6.0764 30.8203 5.6503 30.8127C3.90808 30.785 2.16586 30.7875 0.426161 30.8051C0.0731786 30.8077 -0.00246112 30.7043 6.01878e-05 30.369C0.0126667 25.76 0.0126665 21.1486 0.0126665 16.5396C0.0126665 16.3278 0.025273 16.1513 0.201764 15.9774C2.11291 14.0839 4.01145 12.1778 5.91756 10.2742C5.95286 10.2414 6.00076 10.2212 6.1117 10.1506Z"
                      fill="white"
                    />
                    <path
                      d="M15.8238 11.6556C19.2452 7.96696 22.9238 4.74221 26.267 1.18213C26.3099 1.20482 26.3528 1.22751 26.3981 1.25273C26.3981 1.40148 26.3981 1.55024 26.3981 1.699C26.3981 4.8481 26.3906 7.99721 26.4082 11.1438C26.4107 11.5522 26.3149 11.6708 25.8914 11.6682C22.5859 11.6481 19.2805 11.6556 15.8238 11.6556Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShowSidebar}
              >
                <span className="togg">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 17H19M5 12H19M5 7H19"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg p-0"
                id="navbarSupportedContent"
              >
                {/* <Link to="/" className="newwwwhommee">
                  <button className="homebtnns">
                    Home
                  </button>
                </Link> */}

                <ul className="navbar-nav m-auto">
                  <li class="nav-item active">
                    <HashLink
                      to="/"
                      style={
                        isActive("")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Home
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink
                      to="#about"
                      style={
                        isActive("#about")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      About us
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink
                      to="#benefits"
                      style={
                        isActive("#benefits")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Benefits
                    </HashLink>
                  </li>

                  <li className="nav-item">
                    <HashLink
                      to="#properties"
                      style={
                        isActive("#properties")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Properties
                    </HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink
                      to="#roadmap"
                      style={
                        isActive("#roadmap")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Roadmap
                    </HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink
                      to="#news"
                      style={
                        isActive("#news")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      News
                    </HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink
                      to="#faqs"
                      style={
                        isActive("#faqs")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      FAQ
                    </HashLink>
                  </li>
                  {/* <li className="nav-item ">
                    <HashLink
                      to="#"
                      style={
                        isActive("#")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Whitepaper
                    </HashLink>
                  </li>
                  <li className="nav-item ">
                    <HashLink
                      to="/join"
                      style={
                        isActive("#")
                          ? {
                              color: "#FF8A00",
                            }
                          : {}
                      }
                    >
                      Join whitelist
                    </HashLink>
                  </li> */}
                </ul>

                {/* <a
                  href="https://x.com/estateslicenft"
                  target="_blank"
                  >
                  <button className="twinsbtnss">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                      <path d="M14.4436 1.78125H17.0624L11.341 8.32042L18.0718 17.2188H12.8017L8.67391 11.822L3.95083 17.2188H1.33041L7.45 10.2244L0.993164 1.78125H6.39708L10.1282 6.71413L14.4436 1.78125ZM13.5245 15.6513H14.9756L5.60858 3.26642H4.05137L13.5245 15.6513Z" fill="white" />
                    </svg>
                  </button>
                  </a>
                  <a

                  href="https://t.me/EstateSlice"
                  target="_blank"
                  >
                  <button className="twinsbtnss">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none">
                      <path d="M22.3742 1.99808L19.0734 17.5645C18.8244 18.6631 18.175 18.9366 17.2521 18.419L12.2228 14.7129L9.79608 17.0469C9.52752 17.3155 9.30291 17.5401 8.78534 17.5401L9.14666 12.418L18.468 3.99515C18.8732 3.63382 18.3801 3.43362 17.8381 3.79495L6.31463 11.0508L1.35369 9.49808C0.274593 9.16116 0.255062 8.41897 1.5783 7.9014L20.9826 0.42581C21.881 0.0888964 22.6672 0.626006 22.3742 1.99808Z" fill="white" />
                    </svg>
                  </button>
                  </a>
                  <a
                  href="https://www.instagram.com/estateslicenft"
                  target="_blank">
                  <button className="twinsbtnss">
                  <img src='\assets\footer\instanav.svg' className='' />
                  </button>
                  </a> */}

                <div className="navrightbtsns">
                

                  {token ? (
                    <button
                      onClick={() => logoutUserHandle()}
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal"
                      class="btn button-hedaer"
                    >
                      Logout
                    </button>
                  ) : (
                    <>
                      <Link
                        to="/login"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal"
                        class="btn button-hedaer"
                      >
                        Login
                      </Link>

                      <Link
                        to="https://my.estateslicenft.com/signup"
                        // data-bs-toggle="modal"
                        // data-bs-target="#exampleModal"
                        class="btn button-hedaer"
                      >
                        Signup
                      </Link>
                    </>
                  )}

                  {location?.pathname == "/villaa/:id" ? (
                    <>
                      {account ? (
                        <button
                          onClick={() => logoutHandle()}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          class="btn button-hedaer"
                        >
                          Disconnect
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setModalOpen(true);
                            handleCloseSidebar();
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          class="btn button-hedaer"
                        >
                          Connect Wallet
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className={`wallet-modal ${isModalOpen ? "show" : "hide"}`}>
        <div
          className="modal fade show"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden={!isModalOpen}
          style={{ display: isModalOpen ? "block" : "none" }}
          onClick={handleOutsideClick} // Handle outside click
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div className="cross-icon">
                  <h3 class="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>
                  <button
                    className="cross-img1"
                    // type="button"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"

                    type="button"
                    aria-label="Close"
                    onClick={() => setModalOpen(false)}
                  >
                    <img
                      src="/assets/navbar/Close_MD.svg"
                      className="img-fluid"
                      alt="close"
                      // onClick={() => isModalOpen(false)}
                    />
                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button" onClick={connectMetaMask1}>
                    <img
                      src="\assets\navbar\metamask.svg"
                      className="img-fluid"
                    />
                    <h3 className=""> MetaMask</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                  <button className="modal-button" onClick={trustWallet}>
                    <img
                      src="\assets\navbar\walletconnect.svg"
                      className="img-fluid"
                    />
                    <h3 className="">WalletConnect</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                </div>
                <p className="text-center mt-3  connecting">
                  By connecting, I accept Estate Slice NFT{" "}
                  <span className="common connectinggg">Terms of Service </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        placement="top"
        className="mobile_sidebar"
        show={showsidebar}
        onHide={handleCloseSidebar}
      >
        <Offcanvas.Header closeButton>
          <div className="top-content">
            <a href="#" className="navbar-brand " onClick={handleCloseSidebar}>
              <Link to="/">
                {/* <img
                  src="\Logo\Logo.png"
                  alt="img"
                  width={'204px'}
                  className="img-fluid animate__animated animate__bounce"
                /> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="113"
                  height="23"
                  viewBox="0 0 113 23"
                  fill="none"
                >
                  <path
                    d="M0 22.5746C5.9742 14.7473 16.9762 8.6408 16.9762 8.6408C16.9762 8.6408 28.4566 14.8512 33.9524 22.5746C28.4595 10.0614 16.9762 0 16.9762 0C16.9762 0 5.9742 9.89146 0 22.5746Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M0.0869141 22.4772C6.06259 19.1657 17.0631 16.5819 17.0631 16.5819C17.0631 16.5819 28.5435 19.2103 34.0393 22.4772C28.5479 17.1825 17.0631 12.9272 17.0631 12.9272C17.0631 12.9272 6.06259 17.1115 0.0869141 22.4772Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M0.0869141 22.4773C6.06259 21.0765 17.0631 19.9793 17.0631 19.9793C17.0631 19.9793 28.5435 21.0897 34.0393 22.4724C28.5479 20.2334 17.0631 18.4316 17.0631 18.4316C17.0631 18.4316 6.06259 20.207 0.0869141 22.4773Z"
                    fill="#FF8A00"
                  />
                  <path
                    d="M44.3975 17.2946C41.8435 17.2946 40.1615 16.0909 40.0303 14.0925L40.0226 13.9768H41.9284L41.9439 14.0694C42.0905 15.0261 43.0704 15.6203 44.467 15.6203C45.8713 15.6203 46.7509 14.9953 46.7509 14.0385V14.0308C46.7509 13.1975 46.1722 12.7499 44.737 12.449L43.5411 12.2021C41.3497 11.7469 40.2695 10.7284 40.2695 9.06943V9.06172C40.2772 7.10187 41.9979 5.77473 44.3975 5.77473C46.828 5.77473 48.4175 7.05557 48.5333 8.96141L48.541 9.09258H46.6351L46.6197 8.99999C46.4885 8.02778 45.6321 7.44909 44.3821 7.44909C43.0781 7.4568 42.2679 8.06636 42.2679 8.95369V8.96141C42.2679 9.74844 42.8775 10.2114 44.2201 10.4892L45.4237 10.7361C47.7385 11.2145 48.7493 12.1558 48.7493 13.8919V13.8996C48.7493 15.9752 47.1135 17.2946 44.3975 17.2946Z"
                    fill="#10312B"
                  />
                  <path
                    d="M50.0456 17.1017V8.92283H51.9669V10.2345H52.0054C52.3527 9.33949 53.1937 8.75308 54.2739 8.75308C55.4159 8.75308 56.2415 9.35492 56.5424 10.4043H56.5887C56.9822 9.38579 57.9544 8.75308 59.135 8.75308C60.7476 8.75308 61.8047 9.84874 61.8047 11.4845V17.1017H59.8834V11.9629C59.8834 10.9521 59.3587 10.3734 58.4174 10.3734C57.4915 10.3734 56.8588 11.0601 56.8588 12.0478V17.1017H54.9915V11.8626C54.9915 10.9444 54.436 10.3734 53.5409 10.3734C52.615 10.3734 51.9669 11.0987 51.9669 12.0941V17.1017H50.0456Z"
                    fill="#10312B"
                  />
                  <path
                    d="M65.763 17.2406C64.1272 17.2406 63.0238 16.2298 63.0238 14.7715V14.7561C63.0238 13.3055 64.1503 12.4336 66.1333 12.3101L68.2707 12.179V11.6157C68.2707 10.7901 67.7305 10.2886 66.7583 10.2886C65.8478 10.2886 65.2923 10.7129 65.1688 11.3148L65.1534 11.3842H63.371L63.3787 11.2916C63.4945 9.84103 64.7522 8.75308 66.8278 8.75308C68.8725 8.75308 70.1919 9.83331 70.1919 11.4768V17.1017H68.2707V15.844H68.2244C67.7537 16.7005 66.8432 17.2406 65.763 17.2406ZM64.9374 14.6944C64.9374 15.3656 65.4929 15.7746 66.334 15.7746C67.4373 15.7746 68.2707 15.0416 68.2707 14.0694V13.4521L66.4034 13.5678C65.4543 13.6296 64.9374 14.0385 64.9374 14.6866V14.6944Z"
                    fill="#10312B"
                  />
                  <path
                    d="M71.7428 17.1017V8.92283H73.6641V10.3348H73.7027C73.9573 9.34721 74.6363 8.75308 75.5777 8.75308C75.8168 8.75308 76.0406 8.79166 76.1872 8.83024V10.5663C76.0252 10.5046 75.732 10.4583 75.4079 10.4583C74.32 10.4583 73.6641 11.145 73.6641 12.3564V17.1017H71.7428Z"
                    fill="#10312B"
                  />
                  <path
                    d="M80.2149 17.2638C78.502 17.2638 77.6918 16.5693 77.6918 14.8873V10.4043H76.5344V8.92283H77.6918V6.84724H79.644V8.92283H81.164V10.4043H79.644V14.7252C79.644 15.5277 79.9989 15.7823 80.701 15.7823C80.8862 15.7823 81.0328 15.7592 81.164 15.7514V17.1943C80.9402 17.2252 80.6007 17.2638 80.2149 17.2638Z"
                    fill="#10312B"
                  />
                  <path
                    d="M90.6855 17.2946C87.4834 17.2946 85.4618 15.0879 85.4618 11.5308V11.5231C85.4618 7.96605 87.4911 5.77473 90.6855 5.77473C93.2472 5.77473 95.1299 7.37193 95.4231 9.64041L95.4308 9.71757H93.4786L93.4401 9.57097C93.116 8.29784 92.1361 7.5031 90.6855 7.5031C88.7411 7.5031 87.4988 9.054 87.4988 11.5231V11.5308C87.4988 14.0076 88.7488 15.5663 90.6855 15.5663C92.1052 15.5663 93.1006 14.8024 93.4632 13.4289L93.4864 13.3518H95.4385L95.4231 13.4289C95.1376 15.7129 93.2472 17.2946 90.6855 17.2946Z"
                    fill="#10312B"
                  />
                  <path
                    d="M97.7764 7.75001C97.1746 7.75001 96.7039 7.27162 96.7039 6.70064C96.7039 6.11423 97.1746 5.64355 97.7764 5.64355C98.3783 5.64355 98.8412 6.11423 98.8412 6.70064C98.8412 7.27162 98.3783 7.75001 97.7764 7.75001ZM96.8119 17.1017V8.92283H98.7332V17.1017H96.8119Z"
                    fill="#10312B"
                  />
                  <path
                    d="M103.409 17.2638C101.696 17.2638 100.886 16.5693 100.886 14.8873V10.4043H99.7286V8.92283H100.886V6.84724H102.838V8.92283H104.358V10.4043H102.838V14.7252C102.838 15.5277 103.193 15.7823 103.895 15.7823C104.08 15.7823 104.227 15.7592 104.358 15.7514V17.1943C104.134 17.2252 103.795 17.2638 103.409 17.2638Z"
                    fill="#10312B"
                  />
                  <path
                    d="M106.773 19.8177C106.403 19.8177 106.017 19.7714 105.786 19.7329V18.2668C105.932 18.2977 106.156 18.3363 106.426 18.3363C107.159 18.3363 107.568 18.128 107.815 17.4412L107.931 17.1094L104.991 8.92283H107.09L109.003 15.466H109.057L110.978 8.92283H113L110.06 17.3795C109.435 19.1773 108.478 19.8177 106.773 19.8177Z"
                    fill="#10312B"
                  />
                </svg>
              </Link>
            </a>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav m-auto">
            {/* <button className="homebtnns">
                  Home
                </button> */}

            <li class="nav-item active">
              <HashLink
                to="#navbar"
                onClick={handleCloseSidebar}
                style={
                  isActive("")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="#about"
                onClick={handleCloseSidebar}
                style={
                  isActive("#about")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                About us
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="#benefits"
                onClick={handleCloseSidebar}
                style={
                  isActive("#benefits")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Benefits
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                to="#properties"
                onClick={handleCloseSidebar}
                style={
                  isActive("#properties")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Properties
              </HashLink>
            </li>
            <li className="nav-item ">
              <HashLink
                to="#roadmap"
                onClick={handleCloseSidebar}
                style={
                  isActive("#roadmap")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Roadmap
              </HashLink>
            </li>
            <li className="nav-item ">
              <HashLink
                to="#news"
                onClick={handleCloseSidebar}
                style={
                  isActive("#news")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                News
              </HashLink>
            </li>
            <li className="nav-item ">
              <HashLink
                to="#faqs"
                onClick={handleCloseSidebar}
                style={
                  isActive("#faqs")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                FAQ
              </HashLink>
            </li>
            {/* <li className="nav-item ">
              <HashLink
                to="#"
                style={
                  isActive("#")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Whitepaper
              </HashLink>
            </li>
            <li className="nav-item ">
              <HashLink
                to="/join"
                style={
                  isActive("#")
                    ? {
                        color: "#FF8A00",
                      }
                    : {}
                }
              >
                Join whitelist
              </HashLink>
            </li> */}
            {/* <button data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={handleCloseSidebar}>
              Connect Wallet
            </button> */}
            {/* {
              account ?
                <button className="dissconnect-btn"
                  class="btn button-hedaer"
                  onClick={connectMetaMask1}>
                  Disconnect
                </button>
                :
                <button
                  onClick={() => { setModalOpen(true); handleCloseSidebar(); }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  class="btn button-hedaer"
                >
                  Connect Wallet
                </button>
            } */}

            {token ? (
              <button
                onClick={() => logoutUserHandle()}
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                class="btn button-hedaer"
              >
                Logout
              </button>
            ) : (
              <>
                <button
                  className="btn button-hedaer my-1 mt-3"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>

                <button
                  className="btn button-header my-1" style={{width: "100%"}}
                  onClick={() => (window.location.href = "https://my.estateslicenft.com/signup")} >
                  Signup
                </button>
                
              </>
            )}

            {location?.pathname == "/villaa/:id" ? (
              <>
                {account ? (
                  <button
                    onClick={() => logoutHandle()}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    class="btn button-hedaer"
                  >
                    Disconnect
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setModalOpen(true);
                      handleCloseSidebar();
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    class="btn button-hedaer"
                  >
                    Connect Wallet
                  </button>
                )}
              </>
            ) : null}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default Navbar;
