import React from 'react';
import './Telegram.scss'; // Create a separate SCSS file for styling or reuse the styles from App.scss

const TelegramIcon = () => {
  return (
    <div
      className="telegram-icon"
      onClick={() => window.open('https://t.me/EstateSliceNFT', '_blank')}
    >
      <img src="/assets/footer/telegram-white.svg" alt="Telegram" />
    </div>
  );
};

export default TelegramIcon;
