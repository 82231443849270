// import { useCallback } from "react";
// import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
// import { useToast } from "../state/hooks";
// import { connectorsByName } from "../utils/web3React";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// const useAuth = () => {
//   const { toastError } = useToast();

//   const TomiPayBol = localStorage.getItem('IsTomipay')

//   const login = async (connectorID) => {
//     const connector = connectorsByName[connectorID];
//     if (connector) {
//       // console.log("connector??????", connector)
//       if (connectorID === 'injected') {
//         const res = await connector.activate(11155111);
//         return res;
//       } else {
//         await connector.activate()
//         // if (TomiPayBol == 'false') {
//         //   console.log("here i trust", connector)
//         //   await connector.activate()
//         // } else {
//         //   console.log("here i tomi ", connector)
//         //   setTimeout(async () => {
//         //     let ab = await connector?.provider?.signer?.uri
//         //     console.log("providerNullproviderNullproviderNull", ab)
//         //     // alert(ab)
//         //     if (ab == undefined) {
//         //       localStorage.setItem('provider', 'true');
//         //     }
//         //     else {
//         //       localStorage.setItem('provider', 'false');
//         //     }
//         //     // alert(ab)
//         //     window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(ab);
//         //   }, 3000)
//       }
//     } else {
//       toastError("Can't find connector", "The connector config is wrong");
//     }
//   }

//   const logout = async (connectorID) => {
//     // console.log("disssss", connectorID)
//     const connector = connectorsByName[connectorID];
//     // console.log("disssss", connector)
//     if (connector) {
//       if (connector?.deactivate) {

//         await connector.deactivate()
//       } else {
//         await connector.resetState()
//       }
//       // await connector.deactivate()
//     } else {
//       toastError("Can't find connector", "The connector config is wrong");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   };

//   return { login, logout };
// };

// export default useAuth;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { toast } from "react-toastify";
import { connectorsByName } from "../utils/web3React";
import { setupNetwork } from "../utils/wallet";

const useAuth = () => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

  // const login = async (connectorID) => {
  //   const connector = connectorsByName[connectorID];
  //   console.log("chainnnnnnnn idddd : ", chainId);
  //   if (connector) {
  //     try {
  //       console.log(
  //         `Connecting to chain ${chainId} (Binance Smart Chain Mainnet)...`
  //       );
  //       const hasSetup = await setupNetwork(chainId); // Pass chainId to setupNetwork
  //       if (hasSetup) {
  //         await connector.activate(chainId); // Use chainId for activation
  //         console.log(
  //           `Connected to chain ${chainId} (Binance Smart Chain Mainnet)`
  //         );
  //       } else {
  //         console.error(`Failed to set up chain ${chainId}`);
  //         toast.error(`Couldn't connect to chain ${chainId}`);
  //       }
  //     } catch (error) {
  //       console.error("Error during wallet connection:", error);
  //       toast.error("Error during wallet connection", error);
  //     }
  //   } else {
  //     console.error("Invalid connector");
  //     toast.error("Invalid connector");
  //   }
  // };

  const login = async (connectorId) => {
    const connector = connectorsByName[connectorId];
    const chainId = process.env.REACT_APP_CHAIN_ID;
    if (connector) {
      try {
        await connector.activate(chainId);
        window.localStorage.setItem("connectorId", connectorId);
        console.log("Connected successfully");
      } catch (error) {
        console.error("Connection failed", error);
      }
    } else {
      console.error("Invalid connector ID");
    }
  };

  const logout = async () => {
    const connectorID = window.localStorage.getItem("connectorId");
    const connector = connectorsByName[connectorID];
    if (connector) {
      try {
        if (connector?.deactivate) {
          await connector.deactivate();
        } else {
          await connector.resetState();
        }
        console.log("Wallet disconnected successfully");
        toast.success("Wallet disconnected successfully");
      } catch (error) {
        console.error("Error during wallet disconnection:", error);
        toast.error("Error during wallet disconnection", error);
      }
    } else {
      console.error("Invalid connector");
      toast.error("Invalid connector");
    }
  };

  return { login, logout };
};

export default useAuth;
