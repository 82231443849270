import React from "react";
import "./partners.scss";
import Carousel from 'react-bootstrap/Carousel';
import 'react-image-gallery/styles/css/image-gallery.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



const Partners = () => {
  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 6, // Default number of slides for desktop
    slidesToScroll: 1,
    
  
    responsive: [
      {
        breakpoint: 1024, // Screens smaller than 1024px
        settings: {
          slidesToShow: 3, // Adjust for tablets
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768, // Screens smaller than 768px (mobile)
        settings: {
          slidesToShow: 2, // Show 2 cards on smaller screens
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 450, // Screens smaller than 450px (mobile)
        settings: {
          slidesToShow: 2, // Show 1 full card
          slidesToScroll: 1
        }
      }
    ]
};  
  

  return (
    <>
      <section className="Roadmaps" id="partners">

        <div className="custom-container">
          <div className="myyynewdiv">
            <div className="toperparxraa">
              <p className="commonhessdsading ">
              They Trust Us
              </p>
              <h2 className="dsdsds">Trusted by Industry Leaders</h2>
              <p className="seccssdsparaa">
              We're proud to be trusted by globally recognized brands and pioneers in the industry. Their trust reflects our commitment to excellence and innovation.
              </p>
            </div>
            <div className="maincarddiv p-0">
              <Slider {...settings}>
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\visa.png" />
                    </div>
                    
                  </div>
                </div>

                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\mastercard.png" />
                    </div>
                    
                  </div>
                </div>

                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\binance.png" />
                    </div>
                    
                  </div>
                </div>

                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\btc.png" />
                    </div>
                    
                  </div>
                </div>

                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\eth.png" />
                    </div>
                    
                  </div>
                </div>

                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\usdt.png" />
                    </div>
                    
                  </div>
                </div>
                
                <div className="maincarddiv">
                  <div className="inner_banner">
                    <div className="inner_part">
                      <img className="imgback" src="\assets\deblock.png" />
                    </div>
                    
                  </div>
                </div>
                
              </Slider>
            </div>

            <h2 className="dsdsds info">Contact Us</h2>
            <div className="smartcityouterdiv">
            {/* <h2 className="dsdsds info">Registered Office Address</h2> */}
            <div className="dsdsds adjust">
            <h4> Registered Address</h4>
            <p className="seccssdsparaa">
            10 ANSON ROAD #11-20 INTERNATIONAL PLAZA SINGAPORE (079902)
            </p>
            </div>
            <div className="dsdsds adjust">
            <h4> Company Registration             </h4>
            <p className="seccssdsparaa">
            UEN: 202436378A
            </p>
            </div>
            <div className="dsdsds adjust">
            <h4> Email Us</h4>
            <a className="link-color" href="mailto:contact@estateslicenft.com">contact@estateslicenft.com</a>
            <br></br>
            <a className="link-color" href="mailto:info@estateslicenft.com">info@estateslicenft.com</a>
            </div>
            
            </div>
          </div>
        </div>

      </section>

    </>
  );
};

export default Partners;
