import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import { Web3ReactProvider } from "@web3-react/core";
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnectV2.js';
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask';

// Get the root DOM element
const domNode = document.getElementById('root');
const root = createRoot(domNode);

// Define the connectors for Web3
const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks],
];

// Render the application
root.render(
  <Provider store={store}>
    <Web3ReactProvider connectors={connectors}>
      <App />
    </Web3ReactProvider>
  </Provider>
);
