// import React from "react";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./connect.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbarmint from "../landing/headermint/Navbarmint";
// import { doc, setDoc } from "firebase/firestore";
// import { db } from "../../firebase/firebase";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import Loader from "../../hooks/loader";
import useAuth from "../../hooks/useAuth";
import { setupNetwork } from "../../utils/wallet";

const Connect = () => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const { login, logout } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const { account } = useWeb3React();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the regex
    if (value && !emailRegex.test(value)) {
      setError("Please enter a valid email address.");
    } else {
      setError(""); // Clear error if valid
    }
  };

  // const handleSubmit = async () => {
  //     if (account) {
  //         if (email && name) {
  //             try {
  //                 setLoader(true);
  //                 // Storing name, email, and account (wallet address) in the Firestore document
  //                 await setDoc(doc(db, "users", email), {
  //                     name: name,       // Add name field
  //                     email: email,     // Storing email as a field too
  //                     walletAddress: account // Add wallet address (account)
  //                 });
  //                 console.log("Document written successfully");
  //                 // Reset the form fields
  //                 setLoader(false);
  //                 handleShow();
  //                 setEmail('');
  //                 setName('');        // Reset name field
  //                 // setWalletAddress(''); // Reset wallet address if needed (optional)
  //             } catch (error) {
  //                 setLoader(false);
  //                 console.error("Error writing document:", error);
  //             }
  //         } else {
  //             toast?.error("Please fill the fields");
  //         }
  //     } else {
  //         toast?.error("Please connect your wallet");
  //     }
  // };

  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      let res = await setupNetwork();
      console.log(res, "res in setupNetwork");

      if (res) {
        login("injected");
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", "true");
        handleClose();
      }
    }
  };

  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
      handleClose();
    } else {
      try {
        handleClose();
        login("walletconnect");
        localStorage.setItem("connectorId", "walletconnect");
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };

  return (
    <>
      {loader && <Loader />}

      <div className="container-fluid">
        <div className="  leftrightmarginonly">
          <section className="Connecthead">
            <Navbarmint />
            <div className="joinmain">
              <p>Estate Slice Property NFT</p>
              {/* <img src="\assets\backkkkimg.svg" className="backkkimg"/> */}
              <h1>Now, Please Provide Your BNB Wallet Address</h1>
              <h4>So We Can Send Your NFT If You Win</h4>
              <input
                placeholder="Enter your name"
                className="innnnnput"
                type="text"
                id="fname"
                name="fname"
                value={name}
                onChange={(e) => setName(e?.target?.value)}
              />
              <input
                placeholder="Enter your email address"
                className="innnnnput"
                type="text"
                id="fname"
                name="fname"
                value={email}
                onChange={(e) => handleEmailChange(e)}
              />
              {error && (
                <label
                  className="text-danger"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  {error}
                </label>
              )}
              {account && (
                <input
                  placeholder="Wallet Address"
                  className="innnnnput"
                  type="text"
                  id="fname"
                  name="fname"
                  readOnly
                  value={account}
                />
              )}
              {!account && (
                <>
                  <h4>OR Connect Your Wallet:</h4>
                  <div className="mainnnndevv">
                    <div className="innnnerpart" onClick={connectMetaMask1}>
                      <p className="paraaa1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="28"
                          viewBox="0 0 31 28"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_428_11790)">
                            <path
                              d="M28.638 0L16.8774 8.73432L19.0527 3.58102L28.638 0Z"
                              fill="#E2761B"
                            />
                            <path
                              d="M1.48926 0L13.1552 8.81697L11.0864 3.58102L1.48926 0Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M24.4065 20.2462L21.2744 25.0451L27.9762 26.8885L29.9029 20.3527L24.4065 20.2462Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M0.248047 20.3527L2.16284 26.8885L8.86462 25.0451L5.73256 20.2462L0.248047 20.3527Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M8.48647 12.1381L6.61865 14.9634L13.2729 15.2589L13.0368 8.10815L8.48647 12.1381Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M21.6406 12.1382L17.0315 8.02551L16.8774 15.2589L23.5203 14.9634L21.6406 12.1382Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M8.86475 25.0451L12.8596 23.0946L9.40826 20.3997L8.86475 25.0451Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M17.2676 23.0946L21.2744 25.0451L20.719 20.3997L17.2676 23.0946Z"
                              fill="#E4761B"
                            />
                            <path
                              d="M21.2744 25.0452L17.2676 23.0947L17.5869 25.7071L17.5512 26.806L21.2744 25.0452Z"
                              fill="#D7C1B3"
                            />
                            <path
                              d="M8.86475 25.0452L12.5879 26.806L12.5641 25.7071L12.8596 23.0947L8.86475 25.0452Z"
                              fill="#D7C1B3"
                            />
                            <path
                              d="M12.6465 18.6746L9.31348 17.6934L11.6653 16.6177L12.6465 18.6746Z"
                              fill="#233447"
                            />
                            <path
                              d="M17.4805 18.6746L18.4617 16.6177L20.8254 17.6934L17.4805 18.6746Z"
                              fill="#233447"
                            />
                            <path
                              d="M8.86448 25.0451L9.43178 20.2462L5.73242 20.3527L8.86448 25.0451Z"
                              fill="#CD6116"
                            />
                            <path
                              d="M20.707 20.2462L21.2743 25.0451L24.4064 20.3527L20.707 20.2462Z"
                              fill="#CD6116"
                            />
                            <path
                              d="M23.5203 14.9634L16.8774 15.2589L17.4923 18.6746L18.4735 16.6177L20.8373 17.6934L23.5203 14.9634Z"
                              fill="#CD6116"
                            />
                            <path
                              d="M9.31364 17.6934L11.6774 16.6177L12.6467 18.6746L13.2729 15.2589L6.61865 14.9634L9.31364 17.6934Z"
                              fill="#CD6116"
                            />
                            <path
                              d="M6.61865 14.9634L9.40819 20.3997L9.31364 17.6934L6.61865 14.9634Z"
                              fill="#E4751F"
                            />
                            <path
                              d="M20.8376 17.6934L20.7192 20.3997L23.5207 14.9634L20.8376 17.6934Z"
                              fill="#E4751F"
                            />
                            <path
                              d="M13.2727 15.2589L12.6465 18.6746L13.4267 22.7046L13.6039 17.3979L13.2727 15.2589Z"
                              fill="#E4751F"
                            />
                            <path
                              d="M16.8773 15.2589L16.5586 17.386L16.7001 22.7046L17.4922 18.6746L16.8773 15.2589Z"
                              fill="#E4751F"
                            />
                            <path
                              d="M17.4923 18.6745L16.7002 22.7045L17.2675 23.0946L20.7189 20.3996L20.8372 17.6934L17.4923 18.6745Z"
                              fill="#F6851B"
                            />
                            <path
                              d="M9.31348 17.6934L9.40803 20.3996L12.8594 23.0946L13.4267 22.7045L12.6465 18.6745L9.31348 17.6934Z"
                              fill="#F6851B"
                            />
                            <path
                              d="M17.5515 26.8059L17.5872 25.707L17.2916 25.4466H12.8359L12.5641 25.707L12.5879 26.8059L8.86475 25.0452L10.1647 26.1084L12.8002 27.9405H17.3273L19.9747 26.1084L21.2746 25.0452L17.5515 26.8059Z"
                              fill="#C0AD9E"
                            />
                            <path
                              d="M17.2677 23.0947L16.7004 22.7046H13.4268L12.8595 23.0947L12.564 25.707L12.8357 25.4466H17.2915L17.587 25.707L17.2677 23.0947Z"
                              fill="#161616"
                            />
                            <path
                              d="M29.1345 9.30162L30.1389 4.47955L28.638 0L17.2676 8.43877L21.6407 12.1381L27.8221 13.9465L29.1934 12.351L28.6023 11.9258L29.5478 11.063L28.8152 10.4957L29.7607 9.77437L29.1345 9.30162Z"
                              fill="#763D16"
                            />
                            <path
                              d="M0 4.47955L1.00437 9.30162L0.366308 9.77437L1.31181 10.4957L0.591089 11.063L1.53659 11.9258L0.945504 12.351L2.30489 13.9465L8.48634 12.1381L12.8594 8.43877L1.48902 0L0 4.47955Z"
                              fill="#763D16"
                            />
                            <path
                              d="M27.8224 13.9465L21.641 12.1382L23.5207 14.9634L20.7192 20.3997L24.4067 20.3528H29.9031L27.8224 13.9465Z"
                              fill="#F6851B"
                            />
                            <path
                              d="M8.48642 12.1382L2.30496 13.9465L0.248047 20.3528H5.73257L9.40814 20.3997L6.6186 14.9634L8.48642 12.1382Z"
                              fill="#F6851B"
                            />
                            <path
                              d="M16.8773 15.2589L17.2674 8.4388L19.0644 3.58105H11.0859L12.8592 8.4388L13.2725 15.2589L13.4146 17.4098L13.4265 22.7046H16.7001L16.7239 17.4098L16.8773 15.2589Z"
                              fill="#F6851B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_428_11790">
                              <rect width="30.1984" height="28" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        Metamask
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M7.5 17L17.5 7M17.5 7H9.5M17.5 7V15"
                          stroke="#FF8A00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="innnnerpart" onClick={trustWallet}>
                      <p className="paraaa1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31"
                          height="28"
                          viewBox="0 0 31 28"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_428_11825)">
                            <path
                              d="M6.32324 8.68407C11.3525 3.77198 19.5166 3.77198 24.5459 8.68407L25.1514 9.27977C25.4053 9.52391 25.4053 9.9243 25.1514 10.1684L23.081 12.1899C22.9541 12.3169 22.749 12.3169 22.6221 12.1899L21.792 11.3794C18.2764 7.95165 12.5928 7.95165 9.07714 11.3794L8.18847 12.2485C8.06152 12.3755 7.85644 12.3755 7.72949 12.2485L5.65918 10.227C5.40527 9.9829 5.40527 9.58251 5.65918 9.33837L6.32324 8.68407ZM28.833 12.8637L30.6787 14.6606C30.9326 14.9048 30.9326 15.3052 30.6787 15.5493L22.3682 23.6645C22.1142 23.9086 21.7041 23.9086 21.46 23.6645L15.5615 17.9028C15.5029 17.8442 15.3955 17.8442 15.3369 17.9028L9.43847 23.6645C9.18457 23.9086 8.77441 23.9086 8.53027 23.6645L0.19043 15.5493C-0.0634765 15.3052 -0.0634765 14.9048 0.19043 14.6606L2.03613 12.8637C2.29004 12.6196 2.7002 12.6196 2.94434 12.8637L8.84277 18.6255C8.90136 18.684 9.00878 18.684 9.06738 18.6255L14.9658 12.8637C15.2197 12.6196 15.6299 12.6196 15.874 12.8637L21.7724 18.6255C21.831 18.684 21.9385 18.684 21.9971 18.6255L27.8955 12.8637C28.1689 12.6196 28.5791 12.6196 28.833 12.8637Z"
                              fill="#0270FF"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_428_11825">
                              <rect
                                width="30.1984"
                                height="28"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                        Connect Wallet
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 17L17 7M17 7H9M17 7V15"
                          stroke="#FF8A00"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              )}

              <button disabled>Join Now</button>
              {/* <button onClick={() => handleSubmit()} >Join Now</button> */}
              <div className="myyyyyyyyyy">
                <img src="\assets\imgleft.svg" className="imgleftt img-fluid" />
              </div>

              <img src="\assets\imgright.svg" className="imgright img-fluid" />
            </div>
          </section>
        </div>
      </div>

      <Modal className="ambmodalmain" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="myyyydiv">
            <svg
              className="imgsvg"
              xmlns="http://www.w3.org/2000/svg"
              width="213"
              height="214"
              viewBox="0 0 213 214"
              fill="none"
            >
              <path
                d="M159.75 160.25C189.159 130.841 189.159 83.1593 159.75 53.7501C130.341 24.3409 82.6593 24.3409 53.2501 53.7501C23.8409 83.1592 23.8409 130.841 53.2501 160.25C82.6593 189.659 130.341 189.659 159.75 160.25Z"
                fill="#3FC700"
              />
              <path
                d="M193.678 84.0903C189.64 68.6834 181.534 54.567 170.235 43.2675C153.212 26.2446 130.578 16.87 106.504 16.87C82.4294 16.87 59.7956 26.2446 42.7727 43.2675C31.4744 54.5658 23.3686 68.6816 19.3306 84.0885L16.2642 83.2852C20.4442 67.3344 28.8362 52.722 40.5318 41.0267C58.1529 23.4047 81.5829 13.6999 106.504 13.6999C131.425 13.6999 154.855 23.4047 172.476 41.0267C184.172 52.7223 192.564 67.3362 196.744 83.287L193.678 84.0903Z"
                fill="#3FC700"
              />
              <path
                d="M200.903 82.0372C201.661 85.2393 199.678 88.4502 196.476 89.2071C193.273 89.9644 190.063 87.9815 189.306 84.7794C188.549 81.5773 190.531 78.3665 193.733 77.6095C196.936 76.8523 200.146 78.8349 200.903 82.0372Z"
                fill="#3FC700"
              />
              <path
                d="M12.1046 82.0372C11.3473 85.2393 13.3302 88.4502 16.5323 89.2071C19.7347 89.9644 22.9452 87.9815 23.7025 84.7794C24.4594 81.5773 22.4768 78.3665 19.2747 77.6095C16.0721 76.8523 12.8615 78.8349 12.1046 82.0372Z"
                fill="#3FC700"
              />
              <path
                d="M106.504 200.297C81.5835 200.297 58.1535 190.592 40.5315 172.97C29.1012 161.54 20.8049 147.29 16.5386 131.758L19.5948 130.919C23.7152 145.921 31.731 159.687 42.7721 170.73C59.7959 187.753 82.4296 197.127 106.504 197.127C130.577 197.127 153.21 187.753 170.234 170.73C181.277 159.687 189.291 145.921 193.411 130.92L196.468 131.76C192.202 147.29 183.906 161.54 172.475 172.97C154.853 190.592 131.424 200.297 106.504 200.297Z"
                fill="#3FC700"
              />
              <path
                d="M12.3885 133.053C11.5963 129.859 13.544 126.627 16.7376 125.835C19.9311 125.043 23.1634 126.991 23.9556 130.185C24.7477 133.378 22.8004 136.61 19.6065 137.403C16.4124 138.194 13.1804 136.247 12.3885 133.053Z"
                fill="#3FC700"
              />
              <path
                d="M200.619 133.053C201.411 129.859 199.464 126.627 196.27 125.835C193.076 125.043 189.844 126.991 189.052 130.185C188.26 133.378 190.207 136.61 193.401 137.403C196.595 138.194 199.827 136.247 200.619 133.053Z"
                fill="#3FC700"
              />
              <path
                d="M138.177 71.8188L92.7971 117.199L74.8309 99.2324L62.3413 111.722L92.7971 142.178L150.667 84.3083L138.177 71.8188Z"
                fill="white"
              />
            </svg>
            <h1>Congratulations</h1>
            <p>You have been added in the whitelist</p>
            <button onClick={handleClose}>Done</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Connect;
