import React, { useEffect } from "react";

import './news.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const News = () => {
    const settings = {
        // dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 4, // Default number of slides for desktop
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024, // Screens smaller than 1024px
            settings: {
              slidesToShow: 3, // Adjust for tablets
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 768, // Screens smaller than 768px (mobile)
            settings: {
              slidesToShow: 2, // Show 2 cards on smaller screens
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450, // Screens smaller than 450px (mobile)
            settings: {
              slidesToShow: 1, // Show 1 full card
              slidesToScroll: 1
            }
          }
        ]
    };  
      


    return (
        <>
            <section className="ourteam" id="news">
                <div className="custom-container p-0">
                    <div className="mainouterdivproperties">
                        <div className="rightfstt_aboutvvccc"  >
                            <p className="aboutdfdvv ">
                                News
                            </p>

                            <h3 className="myparxxa">
                            They talk about us
                            </h3>
                            <p className="myparasefrecsde">
                            Showcases the voices of those who trust us, sharing their experiences and the impact we've made. Explore the links below to learn more.
                            </p>



                        </div>
                        

                    </div>

                    <div className="ourteammainouter_divseddc myyynewdiv">
                        <Slider {...settings}>
                          <a href="https://fintechmode.com/press-releases/buy-your-property-in-nft-from-500-with-estate-slice-nft/" target="blank">  
                          <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\news\Fintechmode.jpg" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Buy Your Property In Nft From $500</h1>
                                        <p>by Fintech Mode</p>
                                    </div>
                                </div>
                            </div>
                            </a>
                            <a href="https://www.benzinga.com/content/42570602/buy-your-property-in-nft-from-500-with-estate-slice-nft" target="blank">  
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\news\Benzinga.jpg" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Buy Fractional Properties</h1>
                                        <p>by Benzinga</p>
                                    </div>
                                </div>
                            </div>
                            </a>
                            <a href="https://geekocoin.net/buy-your-property-in-nft-from-500-with-estate-slice-nft/" target="blank">  
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\news\geekocoin.jpg" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Buy NFT Property from EstateSlice</h1>
                                        <p>by GEEKOCOIN</p>
                                    </div>
                                </div>
                            </div>
                            </a>
                            <a href="https://thenewscrypto.com/buy-proportional-properties-on-blockchain/" target="blank">  
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\news\thenewscrypto.jpg" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>Buy Properties on Blockchain</h1>
                                        <p>by The News Crypto</p>
                                    </div>
                                </div>
                            </div>
                            </a>
                            <a href="https://techbullion.com/buy-proportional-properties-on-blockchain/" target="blank">  
                            <div className="maincarddiv">
                                <div className="inercard">
                                    <div className="imhgggggcard">
                                        <img src="\assets\news\techbullion.jpg" className="imhgggggcard" />
                                    </div>
                                    <div className="body_carddd">
                                        <h1>BUY PROPORTIONAL PROPERTIES</h1>
                                        <p>by Tech Bullion</p>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </Slider>
                    </div>

                </div>
            </section>
        </>
    );
};

export default News;
