import '../../App.scss';
import React, { useState, useEffect } from 'react';
import Banner from './main-banner/Banner.js';
import Affiliateprogram from './Affiliateprogram/Affiliateprogram.js';
import Navbar from './header/Navbar.js';
import Benefits from './Benefits/Benefits.js';
import Information from './Information/Information.js';
import Buynow from './buynow/Buynow.js';
import Faqs from './faqs/Faqs.js';
import Ourteam from './ourteam/Ourteam.js';
import Roadmap from './roadmap/Roadmap.js';
import Smartcity from './Smartcity/Smartcity.js';
import News from './news/News.js';
import Partners from './partners/Partners.js';
// import Loader from '../../Assests/loader.svg'; // Ensure correct path
import axios from 'axios';
import FetchHash from '../../hooks/dataFetchers/fetchHash.js';
import Loader from '../../hooks/loader.js';
import TelegramIcon from '../Telegram/Telegram';

function Landing() {

  const [loading, setLoading] = useState(true);

  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null);

  console.log(properties, "propertiess");


  useEffect(() => {
    // Function to fetch the data for both items
    const fetchProperties = async () => {
      try {
        setLoading(true);
        // Fetching data from the APIs
        const vilaResponse = await fetch('https://due-ever-anywhere-port.quicknode-ipfs.com/ipfs/QmZkgD5TC3rz7WPksobN9efqKub99mcNcfQwLNYokVjM1t/vila.json');
        const duplexResponse = await fetch('https://due-ever-anywhere-port.quicknode-ipfs.com/ipfs/QmZkgD5TC3rz7WPksobN9efqKub99mcNcfQwLNYokVjM1t/duplex.json');

        // Parsing the JSON response
        const vilaData = await vilaResponse.json();
        const duplexData = await duplexResponse.json();

        // Logging individual responses for debugging
        console.log("Vila Data:", vilaData);
        console.log("Duplex Data:", duplexData);

        // Combine the objects into a single array
        const combinedData = [];
        combinedData.push(...vilaData); // Push all vilaData objects into combinedData
        combinedData.push(...duplexData); // Push all duplexData objects into combinedData

        // Logging the combined data for debugging
        console.log("Combined Data:", combinedData);

        // Update the state with the combined data
        setProperties(combinedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchProperties();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Banner />
          <Affiliateprogram />
          <Benefits />
          <Ourteam metadataArray={properties} />
          <Smartcity />
          <Information />
          <Roadmap />
          <Buynow />
          <News />
          <Faqs />
          <Partners />
          <TelegramIcon />
        </>
      )}
    </>
  );
}

export default Landing;
