import React from 'react';
import { Modal } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import { useNavigate } from 'react-router-dom';

function Sucessmodal({ show, handleClose, text, nftBuy }) {

    const navigate = useNavigate();

    return (
        <>
            <Modal className="ambmodalmain" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="myyyydiv text-center">
                        <svg className="imgsvg" xmlns="http://www.w3.org/2000/svg" width="213" height="214" viewBox="0 0 213 214" fill="none">
                            <path d="M159.75 160.25C189.159 130.841 189.159 83.1593 159.75 53.7501C130.341 24.3409 82.6593 24.3409 53.2501 53.7501C23.8409 83.1592 23.8409 130.841 53.2501 160.25C82.6593 189.659 130.341 189.659 159.75 160.25Z" fill="#3FC700" />
                            <path d="M193.678 84.0903C189.64 68.6834 181.534 54.567 170.235 43.2675C153.212 26.2446 130.578 16.87 106.504 16.87C82.4294 16.87 59.7956 26.2446 42.7727 43.2675C31.4744 54.5658 23.3686 68.6816 19.3306 84.0885L16.2642 83.2852C20.4442 67.3344 28.8362 52.722 40.5318 41.0267C58.1529 23.4047 81.5829 13.6999 106.504 13.6999C131.425 13.6999 154.855 23.4047 172.476 41.0267C184.172 52.7223 192.564 67.3362 196.744 83.287L193.678 84.0903Z" fill="#3FC700" />
                            <path d="M200.903 82.0372C201.661 85.2393 199.678 88.4502 196.476 89.2071C193.273 89.9644 190.063 87.9815 189.306 84.7794C188.549 81.5773 190.531 78.3665 193.733 77.6095C196.936 76.8523 200.146 78.8349 200.903 82.0372Z" fill="#3FC700" />
                            <path d="M12.1046 82.0372C11.3473 85.2393 13.3302 88.4502 16.5323 89.2071C19.7347 89.9644 22.9452 87.9815 23.7025 84.7794C24.4594 81.5773 22.4768 78.3665 19.2747 77.6095C16.0721 76.8523 12.8615 78.8349 12.1046 82.0372Z" fill="#3FC700" />
                            <path d="M106.504 200.297C81.5835 200.297 58.1535 190.592 40.5315 172.97C29.1012 161.54 20.8049 147.29 16.5386 131.758L19.5948 130.919C23.7152 145.921 31.731 159.687 42.7721 170.73C59.7959 187.753 82.4296 197.127 106.504 197.127C130.577 197.127 153.21 187.753 170.234 170.73C181.277 159.687 189.291 145.921 193.411 130.92L196.468 131.76C192.202 147.29 183.906 161.54 172.475 172.97C154.853 190.592 131.424 200.297 106.504 200.297Z" fill="#3FC700" />
                            <path d="M12.3885 133.053C11.5963 129.859 13.544 126.627 16.7376 125.835C19.9311 125.043 23.1634 126.991 23.9556 130.185C24.7477 133.378 22.8004 136.61 19.6065 137.403C16.4124 138.194 13.1804 136.247 12.3885 133.053Z" fill="#3FC700" />
                            <path d="M200.619 133.053C201.411 129.859 199.464 126.627 196.27 125.835C193.076 125.043 189.844 126.991 189.052 130.185C188.26 133.378 190.207 136.61 193.401 137.403C196.595 138.194 199.827 136.247 200.619 133.053Z" fill="#3FC700" />
                            <path d="M138.177 71.8188L92.7971 117.199L74.8309 99.2324L62.3413 111.722L92.7971 142.178L150.667 84.3083L138.177 71.8188Z" fill="white" />
                        </svg>
                        <h1>Congratulations</h1>
                        {
                            text ?
                                <p>
                                    {text}
                                </p>
                                :
                                <p>You have been successfully minted the NFT.</p>
                        }
                        {
                            nftBuy ?
                                <button
                                    onClick={() => {
                                        window.location.href = "https://my.estateslicenft.com/user/purchasednfts";
                                    }}
                                    className="btn btn-primary">View NFT</button>
                                :
                                <button onClick={() => handleClose()} className="btn btn-primary">Done</button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Sucessmodal;
