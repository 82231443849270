// import { initializeConnector } from '@web3-react/core'
// import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

// // import { MAINNET_CHAINS } from '../chains'

// // const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)
// // const TomiPayBol = localStorage.getItem('IsTomipay')
// // let ab = TomiPayBol ==true || TomiPayBol==null ? false : true
// // console.log("TomiPayBol",typeof TomiPayBol)
// // alert(TomiPayBol)
// export const [walletConnectV2, hooks] = initializeConnector(
//   (actions) => {
//     // Log the actions here
//     // alert(typeof TomiPayBol)
//     // let a =TomiPayBol == 'false' ? true : false
//     // console.log("actions:", TomiPayBol,a);
//     // alert(a)
//       return new WalletConnectV2({
//         actions,
//         options: {
//           projectId: "56e76179389975627afbbb8fdb7d6712",
//           chains: [1],
//           optionalChains: [4],
//           showQrModal: true
//         },
//         timeout: 10000,
//         onError: err => {
//           console.log('error in connector:', err);
//         }
//       });
//   }
// );

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

// import { MAINNET_CHAINS } from '../chains'

// const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)

// console.log("mainnet", mainnet)
// console.log("optionalChains", optionalChains)

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "56e76179389975627afbbb8fdb7d6712",
        chains: [process.env.REACT_APP_CHAIN_ID],
        optionalChains: [process.env.REACT_APP_CHAIN_ID],
        showQrModal: true,
      },

      onError: (err) => {
        console.log("error in connector::::", err);
      },
    })
);

// export const [walletConnectV2, hooks] = initializeConnector(
//   (actions) =>
//     new WalletConnectV2({
//       actions,
//       options: {
//         projectId: "202a3d538b088d735b99722b0cea4910",
//         chains: [1116],
//         optionalChains: [1116],
//         showQrModal: true
//       },
//       timeout : 10000,
//       onError: (err => {
//         console.log('erron in connector::::', err)
//       })
//     })
// )
