import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { NftPurchaseContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const FetchHash = () => {

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.nftPurchase;
  const contract = NftPurchaseContract(tokenAddress, web3);

  const FetchHashHook = useCallback(
    async (index) => {
      try {
        // const gas = await contract.methods.mintCategories(0).estimateGas({
        //   from: account
        // });
        const details = await contract.methods.mintCategories(index).call({
          from: account
        });
        console.log(details, "Minting Price");
        
        return details;
      } catch (error) {
        console.log(error, "Minting Price error in ");
        
        throw error;
      }
    },
    [contract, account]
  );
  return { FetchHashHook: FetchHashHook };
};
export default FetchHash;